import { createAction, createReducer } from '@reduxjs/toolkit'
import { Domain } from 'core'

type Prescription = Domain.Prescription.Prescription

type State = {
  prescription?: Array<Prescription>
}

export function createInitialState(): State {
  return { prescription: undefined }
}

const SET_DONE_PRESCRIPTIONS = 'done-prescriptions/set' as const
const CLEAR_DONE_PRESCRIPTIONS = 'done-prescriptions/clear' as const

export const setDonePrescriptions = createAction<Array<Prescription>>(SET_DONE_PRESCRIPTIONS)
export const clearDonePrescriptions = createAction(CLEAR_DONE_PRESCRIPTIONS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setDonePrescriptions, (state, action) => {
      state.prescription = action.payload
    })
    .addCase(clearDonePrescriptions, state => {
      state.prescription = undefined
    })
    .addDefaultCase(() => {
      return
    })
})
