import { createAction, createReducer } from '@reduxjs/toolkit'

type State = {
  hasNotCompatibleNotification: boolean
}

const initialState = { hasNotCompatibleNotification: false }

export function createInitialState(): State {
  return initialState
}

const SET_HAS_NOT_COMPATIBLE_NOTIFICATION = 'has-not-compatible-notification/set' as const
const CLEAR_HAS_NOT_COMPATIBLE_NOTIFICATION = 'has-not-compatible-notification/clear' as const

export const setHasNotCompatibleNotification = createAction<boolean>(SET_HAS_NOT_COMPATIBLE_NOTIFICATION)
export const clearHasNotCompatibleNotification = createAction(CLEAR_HAS_NOT_COMPATIBLE_NOTIFICATION)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setHasNotCompatibleNotification, (state, action) => {
      state.hasNotCompatibleNotification = action.payload
    })
    .addCase(clearHasNotCompatibleNotification, state => {
      state.hasNotCompatibleNotification = initialState.hasNotCompatibleNotification
    })
    .addDefaultCase(() => {
      return
    })
})
