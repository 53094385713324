import React, { useCallback, useEffect } from 'react'
import AdminPharmacyPage from 'components/screens/AdminPharmacyPage'
import { Selectors, Usecases } from 'core'
import { hooks } from 'libs'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { clearIsSaveSuccess, clearShopInformation } from 'core/modules/shopInformation'
import { ShopInformation } from 'core/domain/shopInformation'

export default function AdminPharmacyContainer() {
  const dispatch = useAppDispatch()
  const token = hooks.useAuth().idToken
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const shopInformation = useAppSelector((state: RootState) => state.shopInformation.shopInformation)
  const isSaveSuccess = useAppSelector((state: RootState) => state.shopInformation.isSaveSuccess)
  const isCommunicating = useAppSelector((state: RootState) => Selectors.Network.isCommunicating(state))

  useEffect(() => {
    if (shopId) {
      dispatch(Usecases.getShopInformation.sendGetShopInformationRead(token, shopId))
      return () => {
        dispatch(clearShopInformation())
        dispatch(clearIsSaveSuccess())
      }
    }
  }, [shopId])

  const onSave = useCallback(
    (shopInformation: ShopInformation) => {
      if (shopId == null) return
      dispatch(Usecases.setShopInformation.sendPutShopInformation(token, shopId, shopInformation))
    },
    [shopId],
  )

  return (
    <AdminPharmacyPage
      shopInformation={shopInformation}
      isSaveSuccess={isSaveSuccess}
      isLoading={isCommunicating}
      onSave={onSave}
    />
  )
}
