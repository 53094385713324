import * as React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppNavigate } from './hooks'

import { hooks } from 'libs'
import InitializePage from 'components/screens/InitializePage'

/**
 * By using the isLoggedIn of children() of this component, we switch Routes based on the time a user has already logged in or not
 * We're going to confirm login if we can't verify session in auth: Auth like the first accessing or reloading
 *
 * - If we have not confirmed session in Auth0, we're going to confirm that
 * - If we have confirmed session in Auth0, just diplay a page
 * - If we have not confirmed session in Auth0, we're going to redirect a user to the login page
 */

function RouteGroupSwitch() {
  const { navigate } = useAppNavigate()
  const location = useLocation()
  const { sessionChecked, renewSession } = hooks.useAuth()
  const [isSessionCheckRequired, setSessionCheckRequired] = React.useState<boolean>(!sessionChecked)

  React.useEffect(() => {
    if (isSessionCheckRequired) {
      renewSession()
        .then(() => {
          // Login session recovered
          setSessionCheckRequired(false)
        })
        .catch(() => {
          // No login session, login required
          navigate('/login', { replace: true, state: { from: location } })
          setSessionCheckRequired(false)
        })
    }
  }, [navigate, location, renewSession, isSessionCheckRequired])

  if (isSessionCheckRequired) return <InitializePage />

  return <Outlet />
}

export default RouteGroupSwitch
