import React from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { Box, Button, CircularProgress } from 'components/atoms'
import { hooks } from 'libs'

const RootBox = styled(Box)({
  backgroundColor: '#FAFAFA',
})

const InnerDiv = styled('div')({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 2rem',
})

const BrandLogoTypeH1 = styled('h1')({
  padding: 0,
  margin: '0 1rem 0 0',
  fontSize: '20px',
  fontWeight: 'normal',
})

const MenusDiv = styled('div')({
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: `${theme.typography.pxToRem(16)} !important`,
  color: `${theme.palette.text.primary} !important`,
  opacity: 0.87,
}))

const ProgressWrapperDiv = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)} 0`,
}))

const ActionMenuDiv = styled('div')({
  height: '64px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
})

const ActionMenuButton = styled(Button)(({ theme }) => ({
  fontSize: `${theme.typography.pxToRem(16)} !important`,
  color: `${theme.palette.text.primary} !important`,
  opacity: 0.87,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(0.5),
  border: `1px solid rgba(0, 0, 0, 0.5)`,
}))

const UserIconImg = styled('img')(({ theme }) => ({
  width: 24,
  borderRadius: theme.typography.pxToRem(12),
  marginRight: theme.typography.pxToRem(8),
}))

/**
 * interface
 */

export interface HeaderBarProps {
  isCommunicating?: boolean
}

export default function HeaderBar(props: HeaderBarProps) {
  const { isCommunicating = false } = props
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)

  const { idTokenPayload, logout } = hooks.useAuth()

  return (
    <header>
      <RootBox boxShadow={1}>
        <InnerDiv>
          <BrandLogoTypeH1>どこでも処方せん送信管理画面</BrandLogoTypeH1>
          <MenusDiv>
            <Button component={StyledLink} to="/dashboard">
              アカウント
            </Button>
            {shopId && (
              <Button component={StyledLink} to={`/${shopId}/main/prescriptions/in-process`}>
                処方せん
              </Button>
            )}
            {isCommunicating && (
              <ProgressWrapperDiv>
                <CircularProgress size={24} />
              </ProgressWrapperDiv>
            )}
          </MenusDiv>
          <ActionMenuDiv>
            <ActionMenuButton onClick={logout}>
              <UserIconImg src={idTokenPayload.picture} alt={idTokenPayload.name} />
              ログアウト
            </ActionMenuButton>
          </ActionMenuDiv>
        </InnerDiv>
      </RootBox>
    </header>
  )
}
