import { createTheme } from '@mui/material/styles'

import paletteOption from 'styles/palette'
import typographyOption from 'styles/typography'
import breakpointsOption from 'styles/breakpoints'
import shadowsOption from 'styles/shadows'
import buttonOption from 'styles/button'
import type {} from '@mui/x-date-pickers/themeAugmentation'

export const tomodsMcTheme = createTheme({
  breakpoints: breakpointsOption,
  typography: typographyOption,
  palette: paletteOption,
  shadows: shadowsOption,
  button: buttonOption,
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:not(.Mui-selected)': {
            color: '#cacaca'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': { 
            color: '#cacaca',
            '-webkit-text-fill-color': 'initial'
          }
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': { 
            color: '#cacaca',
            '-webkit-text-fill-color': 'initial'
          }
        }
      }
    }
  },
})
