import React, { useCallback } from 'react'
import { hooks } from 'libs'
import LoginPage from 'components/screens/LoginPage'

/**
 * Interface
 */

interface ChildrenProps {
  actions: {
    handleLogin: () => void
  }
}

interface LoginPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

function LoginPageContainer(props: LoginPageContainerProps) {
  const { children } = props
  const { login } = hooks.useAuth()

  const handleLogin = useCallback(() => {
    login()
  }, [])

  return children({
    actions: {
      handleLogin,
    },
  })
}

export default function LoginComponent() {
  return (
    <LoginPageContainer>
      {props => {
        return <LoginPage {...props} />
      }}
    </LoginPageContainer>
  )
}
