import { Domain } from 'core'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { DEFAULT_MESSAGE_TEMPLATE } from 'constants/value'

type MessageTemplate = Domain.MedicationFollowUp.MessageTemplate
type State = { messageTemplates: MessageTemplate[] }

const initialState: MessageTemplate[] = [DEFAULT_MESSAGE_TEMPLATE]

function createInitialState(): State {
  return { messageTemplates: initialState }
}

const SET_MESSAGE_TEMPLETES = 'message-templetes/set' as const
const CLEAR_SET_MESSAGE_TEMPLETES = 'message-templetes/clear' as const

export const setMessageTemplates = createAction<MessageTemplate[]>(SET_MESSAGE_TEMPLETES)
export const clearMessageTemplates = createAction(CLEAR_SET_MESSAGE_TEMPLETES)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setMessageTemplates, (state, action) => {
      state.messageTemplates = action.payload
    })
    .addCase(clearMessageTemplates, state => {
      state.messageTemplates = initialState
    })
    .addDefaultCase(() => {
      return
    })
})
