import { useCallback, useRef } from 'react'
import { setHasNotCompatibleNotification } from 'core/modules/hasNotCompatibleNotification'
import { useAppDispatch } from '.'

// 1000 ms * 60 sec * 20 min
const CHECK_NO_1_SEC = 1000
const CHECK_NO_1_MIN = CHECK_NO_1_SEC * 60
const CHECK_NO_COMPATIBLE_INTERVAL = CHECK_NO_1_MIN * 20

type Props = {
  playNoCompatibleNotification: () => void
}

export const useNotCompatibleNotification = ({ playNoCompatibleNotification }: Props) => {
  const dispatch = useAppDispatch()
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  const checkCb = useCallback(() => {
    // 警告音鳴動
    playNoCompatibleNotification()
    // 通知ポップアップを ON
    dispatch(setHasNotCompatibleNotification(true))
  }, [dispatch])

  const startCheckCb = useCallback(() => {
    // 将来的にDIでLoggerを実装して差し替えると良い
    // eslint-disable-next-line no-console
    console.log('startCheckCb start.')
    if (intervalIdRef.current !== null) {
      // eslint-disable-next-line no-console
      console.log('intervalId is already set.')
      return
    }
    const intervalId = setInterval(() => {
      checkCb()
    }, CHECK_NO_COMPATIBLE_INTERVAL)
    intervalIdRef.current = intervalId
    // eslint-disable-next-line no-console
    console.log('startCheckCb created intervalId.')
  }, [dispatch])

  const stopCheckCb = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('stopCheckCb start')
    if (intervalIdRef.current !== null) clearInterval(intervalIdRef.current)
    intervalIdRef.current = null
    // 通知ポップアップを OFF
    dispatch(setHasNotCompatibleNotification(false))
  }, [dispatch])

  const comfirmNotificationPermission = useCallback(() => {
    Notification.requestPermission()
      .then(() => {
        // 特に処理しない
      })
      .catch(() => {
        // 特に処理しない
      })
  }, [])

  comfirmNotificationPermission()

  return {
    startCheckCb,
    stopCheckCb,
  }
}
