import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { addMessages } from 'core/modules/messages'
import { Options } from 'libs/api'
import { store } from 'core/store'
import { formatDateTime } from 'utils/dateFormatters'

type SendMessagesPayload = Domain.MedicationFollowUp.SendMessagesPayload
type Message = Domain.MedicationFollowUp.Message

function didCallSetSendMessagesApi(dispatch: Dispatch, data: Message | Error, options: Options | undefined) {
  if (data instanceof Error) {
    return
  }
  if (options?.payload === undefined) return
  const messageList = store.getState().messages.messages.messageList
  const message: Message = {
    id: data.id,
    isShop: true,
    errorMessage: data.errorMessage,
    content: options.payload.content,
    sendAt: options.payload.reservedAt ? options.payload.reservedAt + ' 11:00:00' : formatDateTime(new Date()),
  }
  if (messageList.length === 0) {
    dispatch(addMessages([message]))
  } else {
    const sendAtDt = new Date(messageList[messageList.length - 1].sendAt).getTime()
    const dt = new Date().getTime()
    if (dt < sendAtDt) {
      const cloneArr = [...messageList]
      cloneArr.splice(cloneArr.length - 1, 0, message)
      dispatch(addMessages([...cloneArr]))
    } else {
      dispatch(addMessages([...messageList, message]))
    }
  }
}

export function sendPostMessages(idToken: string | undefined, shopId: string | null, payload: SendMessagesPayload) {
  return async (dispatch: Dispatch) => {
    // Send POST API
    await Api.callApi(
      Api.HttpMethod.post,
      `shops/${shopId}/messages`,
      idToken,
      JSON.stringify({
        patientLineId: payload.patientLineId,
        content: payload.content,
        reservedAt: payload.reservedAt,
      }),
      dispatch,
      didCallSetSendMessagesApi,
      { payload: payload },
    )
  }
}
