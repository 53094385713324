import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Interface
 * @children : チルドレン prop
 * @targetId : DOMのID
 */
interface Props {
  children: React.ReactNode
  targetId: string
}

export default function Portal(props: Props) {
  const { children, targetId } = props
  const targetElement = document.getElementById(targetId)
  if (!targetElement) return null
  return ReactDOM.createPortal(children, targetElement)
}
