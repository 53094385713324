import { Domain } from 'core'
import { createAction, createReducer } from '@reduxjs/toolkit'

type Patients = Domain.Patients.Patients

function createInitialState(): Patients {
  return {
    remarks: '',
  }
}

const SET_PATIENTS = 'patients/set' as const
const CLEAR_PATIENTS = 'patients/clear' as const

export const setPatients = createAction<Patients>(SET_PATIENTS)
export const clearPatients = createAction(CLEAR_PATIENTS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setPatients, (state, action) => {
      state.remarks = action.payload.remarks
    })
    .addCase(clearPatients, state => {
      state.remarks = ''
    })
    .addDefaultCase(() => {
      return
    })
})
