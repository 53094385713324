import { createReducer, createAction } from '@reduxjs/toolkit'

type State = { error: Error | null }

export function createInitialState(): State {
  return { error: null }
}

const SET_SOCKET_ERROR = 'socket-error/set' as const
const CLEAR_SOCKET_ERROR = 'socket-error/clear' as const

export const setSocketError = createAction<Error>(SET_SOCKET_ERROR)
export const clearSocketError = createAction(CLEAR_SOCKET_ERROR)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setSocketError, (state, action) => {
      state.error = action.payload
    })
    .addCase(clearSocketError, state => {
      state.error = null
    })
    .addDefaultCase(() => {
      return
    })
})
