import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { Domain } from 'core'
import { setPrescriptionDetail } from 'core/modules/prescriptionDetail'

type Prescription = Domain.Prescription.Prescription

function didCallGetPrescriptionDetailApi(dispatch: Dispatch, data: Prescription | Error) {
  if (data instanceof Error) return
  dispatch(setPrescriptionDetail(data))
}

export function sendGetPrescriptionDetail(idToken: string | undefined, shopId: string, prescriptionId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/{shopId}/prescriptions/{prescriptionId}
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/prescriptions/${prescriptionId}`,
      idToken,
      null,
      dispatch,
      didCallGetPrescriptionDetailApi,
    )
  }
}
