import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

/**
 * Interface
 */

type IconProps = {
  size?: IconSize
  iconName: IconName
}

export type IconSize = 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x'

// See https://fontawesome.com/icons?d=gallery&m=free for more about icon names.
export type IconName =
  | 'user-circle'
  | 'caret-up'
  | 'caret-right'
  | 'caret-left'
  | 'caret-down'
  | 'pen'
  | 'tachometer-alt'
  | 'bookmark'
  | 'tags'
  | 'images'
  | 'edit'
  | 'trash'
  | 'bars'
  | 'home'
  | 'info-circle'
  | 'exclamation-triangle'
  | 'check-circle'
  | 'ellipsis-h'
  | 'check'
  | 'times'
  | 'ban'
  | 'plus'
  | 'sign-in-alt'
  | 'sign-out-alt'
  | 'th-list'

/**
 * Presenter component
 */

export default function IconPresenter(props: IconProps) {
  const { iconName, size } = props
  return <FontAwesomeIcon icon={findIconDefinition({ prefix: 'fas', iconName })} size={size || 'sm'} />
}

/**
 * Combine component
 */

export const Icon: React.FC<IconProps> = ({ ...props }) => {
  return <IconPresenter {...props} />
}
