import React from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import App from 'App'
import reportWebVitals from 'reportWebVitals'
import { StyledEngineProvider } from '@mui/styled-engine'

// document.getElementById("root")がnullを返す可能があるため、nullでないことを"!"で明示する
const container = document.getElementById('root')!
if (!container) {
  throw new Error('The root container element is not found')
}

const root = ReactDOM.createRoot(container)
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </React.StrictMode>,
)

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
