import React from 'react'
import { styled } from '@mui/material/styles'
import { hooks } from 'libs'
import { GenericTemplate } from 'components/templates'
import { HeaderBar, TenantListSection } from 'components/organisms'
import { PageHeader } from 'components/molecules'
import { Typography, Divider } from 'components/atoms'

const StyledDivider = styled(Divider)({
  backgroundColor: '#EFEFEF',
})

export default function DashboardPage() {
  const { shops } = hooks.useAuth()
  const { navigate } = hooks.useAppNavigate()

  return (
    <GenericTemplate headerBar={<HeaderBar />}>
      <PageHeader>
        <Typography variant="h1" component="h2">
          アカウント
        </Typography>
      </PageHeader>
      <StyledDivider />
      <TenantListSection
        shops={shops}
        onClick={(shopId: string) => navigate(`/${shopId}/main/prescriptions/in-process`)}
      />
    </GenericTemplate>
  )
}
