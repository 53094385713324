import React from 'react'
import DashboardPage from 'components/screens/DashboardPage'

interface DashboardPageContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props: any) => React.ReactElement
}

function DashboardPageContainer(props: DashboardPageContainerProps) {
  const { children } = props
  return children({})
}

export default function DashboardPageCompontent() {
  return (
    <DashboardPageContainer>
      {props => {
        return <DashboardPage {...props} />
      }}
    </DashboardPageContainer>
  )
}
