import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Button } from 'components/atoms'
import { ButtonTypes } from 'styles/button'

const ButtonRoot = styled(Button)<{ buttonType: ButtonTypes; disabled?: boolean }>(({
  theme,
  buttonType,
  disabled = false,
}) => {
  const { background } = theme.button[buttonType]
  return {
    ...theme.button[buttonType],
    height: 42,
    opacity: disabled ? '0.3' : '1.0',
    background: disabled ? `${background} !important` : background,
  }
})

const Icon = styled('img')(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(1),
}))

const Label = styled(Typography)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(20),
}))

/**
 * Interface
 */

interface CommonButtonProps {
  className?: string
  type: ButtonTypes
  label: string
  icon?: string
  disabled?: boolean
  click?: () => void
}

export default function CommonButton(props: CommonButtonProps) {
  const { className, type, label, icon, disabled, click } = props

  return (
    <ButtonRoot
      className={className}
      color="secondary"
      buttonType={type}
      disabled={disabled}
      variant="contained"
      onClick={click}>
      {icon && <Icon src={icon} />}
      <Label variant="caption">{label}</Label>
    </ButtonRoot>
  )
}
