import React from 'react'
import { styled } from '@mui/material/styles'

const Root = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * Interface
 */

interface CenterAlignmentTemplateProps {
  children: React.ReactNode
}

export default function CenterAlignmentTemplate(props: CenterAlignmentTemplateProps) {
  return <Root>{props.children}</Root>
}
