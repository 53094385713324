import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { ShopInformation } from 'core/domain/shopInformation'
import { setShopInformation } from 'core/modules/shopInformation'

function didCallGetShopInformationApi(dispatch: Dispatch, data: ShopInformation | Error) {
  if (data instanceof Error) {
    dispatch(
      setShopInformation({
        opening: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
          holiday: '',
        },
        available: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
          holiday: '',
        },
        comment: '',
      }),
    )
    return
  }
  dispatch(setShopInformation(data))
}

export function sendGetShopInformationRead(idToken: string | undefined, shopId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/{shopId}/get-information
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/get-information`,
      idToken,
      null,
      dispatch,
      didCallGetShopInformationApi,
    )
  }
}
