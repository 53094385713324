import { createAction, createReducer } from '@reduxjs/toolkit'
import { PrescriptionWithMedicationFollowUpStatusType as Prescription } from 'libs/hooks/useMedicationFollowUp'

type State = {
  originPrescriptions: Array<Prescription>
  prescriptions: Array<Prescription>
}

export function createInitialState(): State {
  return { originPrescriptions: [], prescriptions: [] }
}

const ORIGIN_SET_FOLLOW_UP_PRESCRIPTIONS = 'origin-follow-up-prescriptions/set' as const
const ORIGIN_CLEAR_FOLLOW_UP_PRESCRIPTIONS = 'origin-follow-up-prescriptions/clear' as const
const SET_FOLLOW_UP_PRESCRIPTIONS = 'follow-up-prescriptions/set' as const
const CLEAR_FOLLOW_UP_PRESCRIPTIONS = 'follow-up-prescriptions/clear' as const

export const setOriginFollowUpPrescriptions = createAction<Array<Prescription>>(ORIGIN_SET_FOLLOW_UP_PRESCRIPTIONS)
export const clearOriginOriginFollowUpPrescriptions = createAction(ORIGIN_CLEAR_FOLLOW_UP_PRESCRIPTIONS)
export const setFollowUpPrescriptions = createAction<Array<Prescription>>(SET_FOLLOW_UP_PRESCRIPTIONS)
export const clearFollowUpPrescriptions = createAction(CLEAR_FOLLOW_UP_PRESCRIPTIONS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setOriginFollowUpPrescriptions, (state, action) => {
      state.originPrescriptions = action.payload
      state.prescriptions = action.payload
    })
    .addCase(clearOriginOriginFollowUpPrescriptions, state => {
      state.originPrescriptions = []
      state.prescriptions = []
    })
    .addCase(setFollowUpPrescriptions, (state, action) => {
      state.prescriptions = action.payload
    })
    .addCase(clearFollowUpPrescriptions, state => {
      state.prescriptions = []
    })
    .addDefaultCase(() => {
      return
    })
})
