import React from 'react'
import { Selectors } from 'core'
import { Outlet } from 'react-router-dom'
import { PageTemplate } from 'components/templates'
import { HeaderBar, Sidebar } from 'components/organisms'
import { useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'

type Props = {
  notificationCleanUpFunc: () => void
}

/**
 * Presenter
 */

export default function MainPage({ notificationCleanUpFunc }: Props) {
  const isCommunicating = useAppSelector((state: RootState) => Selectors.Network.isCommunicating(state))

  return (
    <PageTemplate
      notificationCleanUpFunc={notificationCleanUpFunc}
      headerBar={<HeaderBar isCommunicating={isCommunicating} />}
      sidebar={<Sidebar />}>
      <Outlet />
    </PageTemplate>
  )
}
