import { Shadows } from '@mui/material'

const shadows: Shadows = [
  'none',
  '0px 1px 5px 0px rgba(0,0,0,0.2)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
]

export default shadows
