import React, { useCallback } from 'react'
import { Domain } from 'core'
import { styled } from '@mui/material/styles'
import { Box, Typography, Select, MenuItem, CommonButton } from 'components/atoms'
import { SectionPanel } from 'components/molecules'
import icoEmail from 'assets/images/ico_email.svg'
import icoDone from 'assets/images/ico_done.svg'
import icoNotInterested from 'assets/images/ico_not_interested.svg'

const ActionListWrapperBox = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
  padding: theme.spacing(2),
}))

const StyledSelect = styled(Select)<{ disabled: boolean }>(({ theme, disabled }) => ({
  height: 42,
  width: 124,
  color: '#FFFFFF',
  background: '#2196F3',
  borderWidth: 0,
  borderRadius: 4,
  marginRight: theme.spacing(2),
  textAlign: 'center',
  '&::before': {
    display: 'none',
  },
  '& .MuiSelect-icon': {
    fill: '#FFFFFF',
    color: '#FFFFFF !important',
  },
  opacity: disabled ? '0.3' : '1.0',
}))

/**
 * Interface
 */
/* TODO :
 * 当該コンポーネントでは threshold を分の間隔として使っているので、
 * Props interface に interval 変数を追加する対応を行うこと。
 */
interface StatusChipProps {
  prescription: Prescription | null
  minutes: number[]
  threshold: number
  time: number
  setTime: (time: number) => void
  openModal: (modalStatus: ModalStatus) => void
}

type Prescription = Domain.Prescription.Prescription

export type ModalStatus =
  | 'dispensing'
  | 'dispensed'
  | 'followup'
  | 'followUpComplete'
  | 'completed'
  | 'canceled'
  | 'initial'
  | 'forceCompleted'

export default function PrescriptionActionList(props: StatusChipProps) {
  const { prescription, minutes, threshold, time, setTime, openModal } = props

  const isDisabled = useCallback(
    (status: string) => {
      return !prescription || prescription.status !== status
    },
    [prescription],
  )

  return (
    <Box>
      <SectionPanel>
        <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">処方せん確認の送信</Typography>
          <Box display="flex" alignItems="center">
            <StyledSelect
              value={time}
              disabled={isDisabled('new')}
              onChange={event => setTime(parseInt(event.target.value as string))}>
              {minutes.map((item, index) => {
                return (
                  <MenuItem
                    value={item}
                    key={index}>{`${item > threshold ? threshold + '分以上' : item + '分程度'}`}</MenuItem>
                )
              })}
            </StyledSelect>
            <CommonButton
              type={'primary'}
              label={'処方せん確認済の通知を送る'}
              icon={icoEmail}
              disabled={isDisabled('new')}
              click={() => {
                openModal('dispensing')
              }}
            />
          </Box>
        </ActionListWrapperBox>
        <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">薬剤準備完了の送信</Typography>
          <CommonButton
            type={'primary'}
            label={'薬剤準備完了の通知を送る'}
            icon={icoEmail}
            disabled={isDisabled('dispensing')}
            click={() => {
              openModal('dispensed')
            }}
          />
        </ActionListWrapperBox>
        <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">お薬を渡したので完了する</Typography>
          <CommonButton
            type={'primary'}
            label={'すべての作業を完了'}
            icon={icoDone}
            disabled={isDisabled('dispensed')}
            click={() => {
              openModal('completed')
            }}
          />
        </ActionListWrapperBox>
        <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
          <Typography variant="caption">この注文の処理をやめる</Typography>
          <CommonButton
            type={'cancel'}
            label={'この予約をキャンセル'}
            icon={icoNotInterested}
            disabled={!prescription || prescription.status === 'canceled' || prescription.status === 'completed'}
            click={() => {
              openModal('canceled')
            }}
          />
        </ActionListWrapperBox>
      </SectionPanel>
      <Box marginTop={2}>
        <SectionPanel>
          <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="caption">この処方せんに関して、途中の状態でもすべての工程を完了とします</Typography>
            <CommonButton
              type={'forceCompleted'}
              label={'すべての作業を完了'}
              icon={icoDone}
              disabled={!prescription || prescription.status === 'canceled' || prescription.status === 'completed'}
              click={() => {
                openModal('forceCompleted')
              }}
            />
          </ActionListWrapperBox>
        </SectionPanel>
      </Box>
    </Box>
  )
}
