/**
 * network module
 *  The state is count of network connecting.
 *
 *  Make sure to dispatch actions of END_COMMUNICATION when all of network
 *  communications are ended.
 *
 *  1. dispatching BEGIN_COMMUNICATION -> 1
 *  2. dispatching BEGIN_COMMUNICATION -> 2
 *  3. dispatching END_COMMUNICATION -> 1
 *  4. dispatching BEGIN_COMMUNICATION -> 2
 *  5. dispatching END_COMMUNICATION -> 1
 *  6. dispatching END_COMMUNICATION -> 0
 *
 *  See ../selectors/network.ts to be mapped this state for View tier.
 *
 * */

import { createAction, createReducer } from '@reduxjs/toolkit'

type State = { network: number }

export function createInitialState(): State {
  return { network: 0 }
}

const BEGIN_COMMUNICATION = 'network/begin-communication' as const
const END_COMMUNICATION = 'network/end-communication' as const

export const beginCommunication = createAction(BEGIN_COMMUNICATION)
export const endCommunication = createAction(END_COMMUNICATION)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(beginCommunication, state => {
      state.network = state.network + 1
    })
    .addCase(endCommunication, state => {
      state.network = state.network - 1
    })
    .addDefaultCase(() => {
      return
    })
})
