import { TypographyOptions } from '@mui/material/styles/createTypography'
import { TYPOGRAPHY } from 'constants/index'

const typographies: TypographyOptions = {
  htmlFontSize: 16,
  fontSize: 14,
  button: {
    textTransform: 'none',
  },
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_900,
    fontWeight: 'normal',
  },
  h2: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_700,
    fontWeight: 'normal',
  },
  h3: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_600,
    fontWeight: 'normal',
  },
  h4: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_300,
    fontWeight: 'normal',
  },
  h5: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_200,
    fontWeight: 'normal',
  },
  h6: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
  subtitle1: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_400,
    fontWeight: 'normal',
    lineHight: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_400,
  },
  subtitle2: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
  body1: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_200,
  },
  body2: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_100,
  },
  caption: {
    fontSize: TYPOGRAPHY.TOMODS_DESKTOP_FONT_SIZE_100,
    fontWeight: 'normal',
  },
}

export default typographies
