import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Usecases } from 'core'
import { hooks } from 'libs'
import PrescriptionDetailPage from 'components/screens/PrescriptionDetailPage'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { setError } from 'core/modules/error'
import { clearPrescriptionDetail } from 'core/modules/prescriptionDetail'

export default function PrescriptionDetailPageContainer() {
  const { prescriptionId = '' } = useParams<{ prescriptionId: string }>()

  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const token = hooks.useAuth().idToken

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (shopId) {
      dispatch(Usecases.getPrescriptionDetail.sendGetPrescriptionDetail(token, shopId, prescriptionId))
      return () => {
        dispatch(clearPrescriptionDetail())
      }
    }
  }, [shopId, prescriptionId])

  const changeStatus = (status: string) => {
    if (!shopId) return
    dispatch(Usecases.setPrescriptionStatus.sendPutPrescriptionStatus(token, shopId, prescriptionId, status))
  }
  const changeStatusWithMinute = (status: string, minute: number) => {
    if (!shopId) return
    dispatch(
      Usecases.setPrescriptionStatus.sendPutPrescriptionStatusWithMinute(token, shopId, prescriptionId, status, minute),
    )
  }

  const prescription = useAppSelector((state: RootState) => state.prescriptionDetail.prescription)

  // prescription の取得に失敗した場合は、read API は発火させない
  useEffect(() => {
    if (shopId && prescription) {
      dispatch(Usecases.readPrescription.sendPutPrescriptionRead(token, shopId, prescriptionId))
    }
  }, [shopId, prescription])

  // 詳細画面でステータス変更時にメッセージが送れなかった場合、エラーモーダルを表示
  useEffect(() => {
    // 詳細画面でステータス変更時にメッセージが送れなかった場合は、処方せん詳細の errorMessage に値が含まれる
    if (prescription && prescription.errorMessage) {
      dispatch(setError(new Error(prescription.errorMessage)))
    }
  }, [prescription])

  return (
    <PrescriptionDetailPage
      prescription={prescription}
      changeStatus={changeStatus}
      changeStatusWithMinute={changeStatusWithMinute}
    />
  )
}
