import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { Domain } from 'core'
import { setDonePrescriptions } from 'core/modules/donePrescriptions'

type Prescription = Domain.Prescription.Prescription

type PrescriptionsData = { prescriptions: Array<Prescription> }

function didCallGetDonePrescriptionsApi(dispatch: Dispatch, data: PrescriptionsData | Error) {
  if (data instanceof Error) {
    dispatch(setDonePrescriptions([]))
    return
  }
  dispatch(setDonePrescriptions(data.prescriptions))
}

export function sendGetDonePrescriptions(idToken: string | undefined, shopId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/{shopId}/prescriptions/done
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/prescriptions/done`,
      idToken,
      null,
      dispatch,
      didCallGetDonePrescriptionsApi,
    )
  }
}
