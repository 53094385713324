import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, CommonButton } from 'components/atoms'
import { SectionPanel } from 'components/molecules'
import { ModalStatus } from '../PrescriptionActionList'

const ActionListWrapperBox = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
  padding: theme.spacing(2),
}))

/**
 * Interface
 */
interface StatusChipProps {
  openModal: (modalStatus: ModalStatus) => void
}

export default function PrescriptionDoneActionList(props: StatusChipProps) {
  const { openModal } = props

  return (
    <Box>
      <SectionPanel>
        <ActionListWrapperBox display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">服薬フォローに戻す</Typography>
          <CommonButton
            type={'primary'}
            label={'服薬フォローに戻す'}
            click={() => {
              openModal('followup')
            }}
          />
        </ActionListWrapperBox>
      </SectionPanel>
    </Box>
  )
}
