import { createAction, createReducer } from '@reduxjs/toolkit'
import { Domain } from 'core'

type Prescription = Domain.Prescription.Prescription

type State = {
  prescription: Prescription | null
}

export function createInitialState(): State {
  return { prescription: null }
}

const SET_PRESCRIPTION_DETAIL = 'prescription-detail/set' as const
const CLEAR_PRESCRIPTION_DETAIL = 'prescriptions-detail/clear' as const

export const setPrescriptionDetail = createAction<Prescription>(SET_PRESCRIPTION_DETAIL)
export const clearPrescriptionDetail = createAction(CLEAR_PRESCRIPTION_DETAIL)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setPrescriptionDetail, (state, action) => {
      state.prescription = action.payload
    })
    .addCase(clearPrescriptionDetail, state => {
      state.prescription = null
    })
    .addDefaultCase(() => {
      return
    })
})
