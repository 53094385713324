import { useRef } from "react"

/** タイトル変更関連の定数 */
const NEW_ARRIVAL_TITLE = "*新規着信があります*"
const TITLE_CHANGE_INTERVAL_MILLISEC = 1000
// 3600000 は 1時間
const TITLE_CHANGE_DURATION = 3600000

const setChangeTitleInterval = (): (() => void) | undefined => {
  const titles = document.getElementsByTagName("title")
  if(titles.length === 0) return
  const title = titles[0]
  const titleText = title.textContent
  if(!titleText) return
  let toggleFlag = false

  const intervalId = setInterval(() => {
    title.innerHTML = toggleFlag ? NEW_ARRIVAL_TITLE : titleText
    toggleFlag = !toggleFlag
  }, TITLE_CHANGE_INTERVAL_MILLISEC)

  const timeoutId = setTimeout(() => {
    clearInterval(intervalId)
    title.innerHTML = titleText
  }, TITLE_CHANGE_DURATION)

  const cleanUpFunc =() => {
    clearInterval(intervalId)
    clearTimeout(timeoutId)
    title.innerHTML = titleText
  }
  return cleanUpFunc
}

type ReturnProps = { play: () => void, cleanUpFunc: () => void }
/**
 * 処方せん新着通知時に以下の演出を行う
 * 1. 音声再生および
 * 2. titleタグの更新
 * 3. browserのpush通知
 */
export const usePlayNewArrivalNotification = (): ReturnProps => {
  // このコンポーネントの更新による再レンダリングを防ぐためにuseRefを使用
  const lastCleanUpFunc = useRef<() => void | undefined>()
  const music = new Audio("/arrival-notification-sound.mp3")

  const play = () => {
    lastCleanUpFunc?.current?.()
    music.play()
    const cleanUpFunc = setChangeTitleInterval()
    if(cleanUpFunc) lastCleanUpFunc.current = cleanUpFunc
  }

  const cleanUpFunc = () => {
    lastCleanUpFunc.current?.()
  }

  return { play, cleanUpFunc }
}