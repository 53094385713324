/**
 * Font Size
 *
 * - Desktop
 * - Mobile
 */

/* Desktop scale */
const TOMODS_DESKTOP_FONT_SIZE_50 = '11px'
const TOMODS_DESKTOP_FONT_SIZE_75 = '12px'
const TOMODS_DESKTOP_FONT_SIZE_100 = '14px' // Base size
const TOMODS_DESKTOP_FONT_SIZE_200 = '16px'
const TOMODS_DESKTOP_FONT_SIZE_300 = '18px'
const TOMODS_DESKTOP_FONT_SIZE_400 = '20px'
const TOMODS_DESKTOP_FONT_SIZE_500 = '22px'
const TOMODS_DESKTOP_FONT_SIZE_600 = '24px'
const TOMODS_DESKTOP_FONT_SIZE_700 = '28px'
const TOMODS_DESKTOP_FONT_SIZE_800 = '32px'
const TOMODS_DESKTOP_FONT_SIZE_900 = '36px'
const TOMODS_DESKTOP_FONT_SIZE_1000 = '40px'
const TOMODS_DESKTOP_FONT_SIZE_1100 = '45px'
const TOMODS_DESKTOP_FONT_SIZE_1200 = '50px'
const TOMODS_DESKTOP_FONT_SIZE_1300 = '60px'

/* Mobile scale */
const TOMODS_MOBILE_FONT_SIZE_50 = '13px'
const TOMODS_MOBILE_FONT_SIZE_75 = '15px'
const TOMODS_MOBILE_FONT_SIZE_100 = '17px' // Base size
const TOMODS_MOBILE_FONT_SIZE_200 = '19px'
const TOMODS_MOBILE_FONT_SIZE_300 = '22px'
const TOMODS_MOBILE_FONT_SIZE_400 = '24px'
const TOMODS_MOBILE_FONT_SIZE_500 = '27px'
const TOMODS_MOBILE_FONT_SIZE_600 = '31px'
const TOMODS_MOBILE_FONT_SIZE_700 = '34px'
const TOMODS_MOBILE_FONT_SIZE_800 = '39px'
const TOMODS_MOBILE_FONT_SIZE_900 = '44px'
const TOMODS_MOBILE_FONT_SIZE_1000 = '49px'
const TOMODS_MOBILE_FONT_SIZE_1100 = '55px'
const TOMODS_MOBILE_FONT_SIZE_1200 = '62px'
const TOMODS_MOBILE_FONT_SIZE_1300 = '70px'

export {
  TOMODS_DESKTOP_FONT_SIZE_50,
  TOMODS_DESKTOP_FONT_SIZE_75,
  TOMODS_DESKTOP_FONT_SIZE_100,
  TOMODS_DESKTOP_FONT_SIZE_200,
  TOMODS_DESKTOP_FONT_SIZE_300,
  TOMODS_DESKTOP_FONT_SIZE_400,
  TOMODS_DESKTOP_FONT_SIZE_500,
  TOMODS_DESKTOP_FONT_SIZE_600,
  TOMODS_DESKTOP_FONT_SIZE_700,
  TOMODS_DESKTOP_FONT_SIZE_800,
  TOMODS_DESKTOP_FONT_SIZE_900,
  TOMODS_DESKTOP_FONT_SIZE_1000,
  TOMODS_DESKTOP_FONT_SIZE_1100,
  TOMODS_DESKTOP_FONT_SIZE_1200,
  TOMODS_DESKTOP_FONT_SIZE_1300,
  TOMODS_MOBILE_FONT_SIZE_50,
  TOMODS_MOBILE_FONT_SIZE_75,
  TOMODS_MOBILE_FONT_SIZE_100,
  TOMODS_MOBILE_FONT_SIZE_200,
  TOMODS_MOBILE_FONT_SIZE_300,
  TOMODS_MOBILE_FONT_SIZE_400,
  TOMODS_MOBILE_FONT_SIZE_500,
  TOMODS_MOBILE_FONT_SIZE_600,
  TOMODS_MOBILE_FONT_SIZE_700,
  TOMODS_MOBILE_FONT_SIZE_800,
  TOMODS_MOBILE_FONT_SIZE_900,
  TOMODS_MOBILE_FONT_SIZE_1000,
  TOMODS_MOBILE_FONT_SIZE_1100,
  TOMODS_MOBILE_FONT_SIZE_1200,
  TOMODS_MOBILE_FONT_SIZE_1300,
}
