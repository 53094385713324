import React, { useRef, useEffect, useId } from 'react'
import { useAppSelector } from 'libs/hooks'
import { styled } from '@mui/material/styles'
import { Box, Typography } from 'components/atoms'
import { RootState } from 'core/store'
import { formatDateTime } from 'utils/dateFormatters'

const MessagesBlock = styled(Box)({
  minHeight: 659,
  maxHeight: 659,
  overflowY: 'scroll',
  marginTop: 8,
})

const MessageSection = styled(Box)<{ isshop: string }>(({ isshop }) => ({
  minHeight: 131,
  marginTop: 16,
  display: 'flex',
  justifyContent: isshop === 'true' ? 'flex-end' : 'flex-start',
}))

const MessageBox = styled(Box)<{ isshop: string; isreserved: string }>(({ isshop, isreserved }) => ({
  minWidth: 350,
  maxWidth: 784,
  minHeight: 131,
  borderRadius: 8,
  backgroundColor: isshop === 'true' ? (isreserved === 'true' ? '#FDF2E2' : '#F1F1F1') : '#E2F1FD',
  padding: 24,
  overflowWrap: 'break-word',
}))

const CancelReservedMessage = styled(Typography)({
  marginLeft: 8,
  fontSize: 12,
  alignContent: 'center',
  color: '#C2492C',
  cursor: 'pointer',
})

interface Props {
  token: string | undefined
  userName: string | undefined
  shopId: string | null
  isPostMessage: boolean
  onCancel: (id: number) => void
  getOldMessages: (id: string, sendAt: string) => void
  onMessageListChanged: () => void
  onReservedMessage: (flg: boolean) => void
}

export default function MessagesViewer(props: Props) {
  const scrollViewId = useId()
  const messageList = useAppSelector((state: RootState) => state.messages.messages.messageList)
  const exclusiveStartKey = useAppSelector((state: RootState) => state.messages.messages.exclusiveStartKey)
  const isPostMessage = useRef(false)
  const currentHeight = useRef(0)
  const messageViewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageList.length === 0) return
    if (isPostMessage.current || props.isPostMessage) {
      messageViewRef.current?.scrollTo(0, messageViewRef.current?.scrollHeight)
    } else {
      const ref = document.getElementById(scrollViewId)
      const height = ref?.scrollHeight ? ref?.scrollHeight : 0
      messageViewRef.current?.scrollTo(0, height - currentHeight.current)
    }
    isPostMessage.current = false
    props.onMessageListChanged()

    props.onReservedMessage(isReserved(messageList[messageList.length - 1].sendAt))
  }, [messageList.length])

  const isReserved = (sendAt: string) => {
    const dt = new Date()
    const sendDt = new Date(sendAt)
    return dt.getTime() < sendDt.getTime() ? true : false
  }

  const hasOldMessages = () => {
    if (
      !exclusiveStartKey ||
      exclusiveStartKey.id === '' ||
      exclusiveStartKey.id === undefined ||
      exclusiveStartKey.id === null
    )
      return false
    return true
  }

  const scroll = () => {
    const ref = document.getElementById(scrollViewId)
    if (!hasOldMessages() || !ref || ref.scrollTop !== 0) return
    isPostMessage.current = false
    currentHeight.current = ref.scrollHeight
    props.getOldMessages(exclusiveStartKey.id, exclusiveStartKey.sendAt)
  }

  return (
    <MessagesBlock id={scrollViewId} ref={messageViewRef} onScroll={() => scroll()}>
      {messageList.map(message => (
        <>
          <MessageSection isshop={message.isShop.toString()}>
            <MessageBox isshop={message.isShop.toString()} isreserved={isReserved(message.sendAt).toString()}>
              <Typography display={'flex'} fontWeight={'bold'}>
                {message.isShop ? 'あなた' : props.userName}
                {isReserved(message.sendAt) && (
                  <CancelReservedMessage
                    onClick={() => {
                      props.onCancel(message.id)
                      isPostMessage.current = true
                    }}>
                    {'送信予約をキャンセル'}
                  </CancelReservedMessage>
                )}
              </Typography>
              <Typography>
                {isReserved(message.sendAt) && '送信予定時刻：'}
                {formatDateTime(new Date(message.sendAt))}
              </Typography>
              <Typography whiteSpace={'pre-wrap'} marginTop={1}>
                {message.content}
              </Typography>
            </MessageBox>
          </MessageSection>
        </>
      ))}
    </MessagesBlock>
  )
}
