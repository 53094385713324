import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { SectionPanel } from 'components/molecules'
import { Typography, Box, CommonButton } from 'components/atoms'
import { useViewer } from 'libs/hooks/useViewer'
import icoSelectAll from 'assets/images/ico_select_all.svg'
import icoAddCircle from 'assets/images/ico_add_circle.svg'
import icoRemoveCircle from 'assets/images/ico_remove_circle.svg'
import icoRotateLeft from 'assets/images/ico_rotate_left.svg'
import icoRotateRight from 'assets/images/ico_rotate_right.svg'
import icoArrowLeft from 'assets/images/ico_arrow_left_black.svg'
import icoArrowRight from 'assets/images/ico_arrow_right_black.svg'
import icoPrint from 'assets/images/ico_print.svg'

const Root = styled(Box)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(1136),
  minHeight: theme.typography.pxToRem(664),
}))

const Controller = styled(Box)(({ theme }) => ({
  width: '100%',
  height: theme.typography.pxToRem(64),
  borderWidth: 0,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#EFEFEF',
  padding: `0 ${theme.spacing(2)}`,
  display: 'flex',
  alignItems: 'center',
}))

const ControllerButtons = styled(Box)(({ theme }) => ({
  '& button': {
    height: theme.typography.pxToRem(42),
    marginRight: theme.spacing(1),
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)}`,
}))

const Paper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  '& img': {
    cursor: 'pointer',
  },
}))

const Icon = styled('img')({
  width: 12,
  height: 12,
})

const Print = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  '& button': {
    height: theme.typography.pxToRem(42),
  },
}))

const Viewer = styled(Box)<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  width: '100%',
  height: theme.typography.pxToRem(600),
  backgroundColor: '#EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  cursor: isDragging ? 'grabbing' : 'grab',
}))

const Canvas = styled('canvas')({
  width: '100%',
  height: '100%',
})

/**
 * Interface
 */

interface PrescriptionImageViewerProps {
  images: Array<string>
  onPrint: () => void
}

export default function PrescriptionImageViewer(props: PrescriptionImageViewerProps) {
  const [currentImageIndex, setCurrentImageDataIndex] = useState<number>(0)

  const canvasEl = useRef<HTMLCanvasElement>(null)
  const {
    dragging,
    canvasInfo,
    initialDrawImage,
    redrawImage,
    moveImageStart,
    moveImage,
    moveImageEnd,
    rotateLeft,
    rotateRight,
    zoomIn,
    zoomOut,
    reset,
  } = useViewer()

  useEffect(() => {
    const imageData = props.images[currentImageIndex]
    if (canvasEl.current && imageData) initialDrawImage(canvasEl.current, imageData)
  }, [props.images, currentImageIndex])

  useEffect(() => {
    if (canvasEl.current) redrawImage(canvasEl.current)
  }, [canvasInfo])

  const handleReset = () => {
    reset()
  }

  const handleZoomIn = () => {
    zoomIn()
  }

  const handleZoomOut = () => {
    zoomOut()
  }

  const handleRotateLeft = () => {
    rotateLeft()
  }

  const handleRotateRight = () => {
    rotateRight()
  }

  const handleDown = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (canvasEl.current) moveImageStart(event, canvasEl.current)
  }

  const handleMove = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (canvasEl.current) moveImage(event, canvasEl.current)
  }

  const handleUp = () => {
    moveImageEnd()
  }

  const pageBack = () => {
    const futureIndex = currentImageIndex - 1
    if (futureIndex >= 0) setCurrentImageDataIndex(futureIndex)
  }

  const pageForward = () => {
    const futureIndex = currentImageIndex + 1
    if (props.images.length > futureIndex) setCurrentImageDataIndex(futureIndex)
  }

  return (
    <SectionPanel header={<Typography variant="h6">処方せん画像</Typography>}>
      <Root>
        <Controller>
          <ControllerButtons>
            <CommonButton type={'primary'} icon={icoSelectAll} label={'全体'} click={handleReset} />
            <CommonButton type={'primary'} icon={icoAddCircle} label={'拡大'} click={handleZoomIn} />
            <CommonButton type={'primary'} icon={icoRemoveCircle} label={'縮小'} click={handleZoomOut} />
            <CommonButton type={'primary'} icon={icoRotateLeft} label={'左回転'} click={handleRotateLeft} />
            <CommonButton type={'primary'} icon={icoRotateRight} label={'右回転'} click={handleRotateRight} />
          </ControllerButtons>
          {props.images.length > 0 ? (
            <Paper>
              <Icon src={icoArrowLeft} onClick={() => pageBack()} />
              <StyledTypography variant="caption">{`${currentImageIndex + 1} / ${props.images.length}`}</StyledTypography>
              <Icon src={icoArrowRight} onClick={() => pageForward()} />
            </Paper>
          ) : null}
          <Print>
            <CommonButton type={'primary'} label={'印刷'} icon={icoPrint} click={() => props.onPrint()} />
          </Print>
        </Controller>
        <Viewer isDragging={dragging}>
          <Canvas
            ref={canvasEl}
            onMouseDown={event => handleDown(event)}
            onMouseMove={event => handleMove(event)}
            onMouseUp={handleUp}
            onMouseOut={handleUp}
          />
        </Viewer>
      </Root>
    </SectionPanel>
  )
}
