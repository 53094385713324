import { useCallback, useRef } from 'react'
import { setSocketError } from 'core/modules/socketError'
import { setHasNotification } from 'core/modules/hasNotification'
import { setHasNewMedicationFollowUpNotification } from 'core/modules/hasNewMedicationFollowUpNotification'
import { setHasNewMedicationFollowUpMessageNotification } from 'core/modules/hasNewMedicationFollowUpMessageNotification'
import { useAppDispatch } from '.'

/** リトライ回数の上限（初回 + 5回リトライで） */
const MAX_SOCKET_CONNECTION_RETRY_COUNT = 5

const SCOKET_ERROR_NAME = 'WebSocket通信の接続に失敗'
const SCOKET_ERROR_MESSAGE = 'サーバとの接続が確立していません　ブラウザをリロードして下さい'

/** WebSocketの接続失敗時のエラー */
const SOCKET_ERROR: Error = {
  name: SCOKET_ERROR_NAME,
  message: SCOKET_ERROR_MESSAGE,
}

/** アプリ自身で close した場合のエラーコード */
const SELF_CLOSE_CODE = 4000

/** リトライ待機時間 */
const OPEN_RETRY_TIME = 1000

type Props = {
  playNewArrivalNotification: () => void
}

export const useNotification = ({ playNewArrivalNotification }: Props) => {
  const dispatch = useAppDispatch()
  const socketRef = useRef<WebSocket | null>(null)
  const retryCount = useRef(0)

  const reopen = useCallback((token: string, shopId: string) => {
    if (MAX_SOCKET_CONNECTION_RETRY_COUNT <= retryCount.current) {
      // ５回 リトライ失敗でエラー情報 Store に保存
      dispatch(setSocketError(SOCKET_ERROR))
      return
    }
    retryCount.current++
    socketRef.current = null
    setTimeout(() => {
      openWebSocket(token, shopId)
    }, OPEN_RETRY_TIME)
  }, [])

  const openWebSocket = useCallback(
    (token: string, shopId: string) => {
      if (socketRef.current) return
      const ws = new WebSocket(`${process.env.REACT_APP_TOMODS_SOCKET_ENDPOINT}?token=${token}&shopId=${shopId}`)

      ws.onopen = () => {
        // 接続性こうした場合はリトライ回数リセット
        retryCount.current = 0
      }

      ws.onclose = event => {
        // 自身での close でない場合にはリトライする
        if (event.code !== SELF_CLOSE_CODE) {
          reopen(token, shopId)
        }
      }

      ws.onmessage = event => {
        if (!event.data) return
        playNewArrivalNotification()
        const splittedMessage = event.data.split(',')
        const bannerMessage = splittedMessage.shift()

        // 通知フラグを true に更新
        switch (bannerMessage) {
          case '新しい処方せんが届きました。':
            dispatch(setHasNotification(true))
            break
          case '新しく服薬フォローが登録されました。':
            dispatch(setHasNewMedicationFollowUpNotification(true))
            break
          case '新しいメッセージが届きました。':
            const name = splittedMessage.length > 0 ? splittedMessage.join(',') : ''
            dispatch(setHasNewMedicationFollowUpMessageNotification(name))
            break
          default:
            dispatch(setHasNotification(true))
        }
      }

      socketRef.current = ws
    },
    [dispatch],
  )

  const closeWebSocket = useCallback(() => {
    if (socketRef.current) {
      // アプリ自身で close されたら、自前エラーコードで判別できるようにする
      socketRef.current.close(SELF_CLOSE_CODE)
      socketRef.current = null
    }
  }, [])

  const comfirmNotificationPermission = useCallback(() => {
    Notification.requestPermission()
      .then(() => {
        // 特に処理しない
      })
      .catch(() => {
        // 特に処理しない
      })
  }, [])

  comfirmNotificationPermission()

  return {
    openWebSocket,
    closeWebSocket,
  }
}
