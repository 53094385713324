import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { ListItemIcon } from 'components/atoms'
import { Domain } from 'core'
import icoGeneric from 'assets/images/ico_generic.svg'
import icoOriginal from 'assets/images/ico_original.svg'
import icoBook from 'assets/images/ico_book.svg'
import icoMaternity from 'assets/images/ico_maternity.svg'
import icoFeeding from 'assets/images/ico_feeding.svg'
import icoAllergy from 'assets/images/ico_allergy.svg'
import icoNone from 'assets/images/ico_none.svg'

const Icon = styled('img')({
  width: 28,
  height: 28,
})

/**
 * チェック項目のアイコン
 */

const ICONS: Array<string> = [
  icoGeneric, // ジェネリック医薬品
  icoBook, // お薬手帳
  icoMaternity, // 妊婦
  icoFeeding, // 授乳中
  icoAllergy, // アレルギー
]

/**
 * Interface
 */

interface CheckListIconsProps {
  prescription: Prescription
}

type Prescription = Domain.Prescription.Prescription

export default function CheckListIcons(props: CheckListIconsProps) {
  const checkItems = useMemo(() => {
    // 事前確認ページ改修タスク前でテーブル修正したため、isGenericMedicineとmedicineDesignationの両方で判定するようにする。
    const isRequireGenericMedicine =
      props.prescription.isGenericMedicine || props.prescription.medicineDesignation === 'Generic'
    return [
      isRequireGenericMedicine,
      props.prescription.hasPrescriptionNote,
      props.prescription.isPregnancy,
      props.prescription.isBreastFeeding,
      props.prescription.allergy ? true : false,
    ]
  }, [props.prescription])

  return (
    <ListItemIcon>
      {checkItems.map((checkItem: boolean, index: number) => {
        return index === 0 ? (
          <Icon src={checkItem ? icoGeneric : icoOriginal} alt={`${index}`} key={index} />
        ) : (
          <Icon src={checkItem ? ICONS[index] : icoNone} alt={`${index}`} key={index} />
        )
      })}
    </ListItemIcon>
  )
}
