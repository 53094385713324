import { Domain } from 'core'
import { createAction, createReducer } from '@reduxjs/toolkit'

type MessagesResult = Domain.MedicationFollowUp.GetMessagesResult
type Message = Domain.MedicationFollowUp.Message
type State = { messages: MessagesResult }

const initialState: MessagesResult = {
  messageList: [],
  exclusiveStartKey: {
    id: '',
    sendAt: '',
  },
  isCompleted: false,
}

function createInitialState(): State {
  return { messages: initialState }
}

const ADD_MESSAGES = 'messages/add' as const
const SET_MESSAGES = 'messages/set' as const
const CLEAR_MESSAGES = 'messages/clear' as const

export const addMessages = createAction<Message[]>(ADD_MESSAGES)
export const setMessages = createAction<MessagesResult>(SET_MESSAGES)
export const clearMessages = createAction(CLEAR_MESSAGES)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(addMessages, (state, action) => {
      state.messages.messageList = action.payload
    })
    .addCase(setMessages, (state, action) => {
      state.messages = action.payload
    })
    .addCase(clearMessages, state => {
      state.messages = initialState
    })
    .addDefaultCase(() => {
      return
    })
})
