import React from 'react'
import { Navigate } from 'react-router-dom'
import { CenterAlignmentTemplate } from 'components/templates'

/**
 * Interface
 */

export interface CallbackPageProps {
  authenticated: boolean
  error: Error | string | undefined
}

export default function CallbackPage(props: CallbackPageProps) {
  if (props.error) {
    return <Navigate to="/login" />
  }
  if (props.authenticated) {
    return <Navigate to="/" />
  } else {
    return <CenterAlignmentTemplate>Loading...</CenterAlignmentTemplate>
  }
}
