const DateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
}

/**
 * 日付フォーマット(yyyy/MM/dd HH:mm:ss)
 */
export function formatDateTime(date: Date): string {
  return new Intl.DateTimeFormat('jp-JP', DateTimeOptions).format(date)
}
