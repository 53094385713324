import React from 'react'
import { styled } from '@mui/material/styles'
//
import { Domain } from 'core'
import { SectionPanel } from 'components/molecules'
import { Typography, Box, Table, TableHead, TableRow, TableCell, TableBody } from 'components/atoms'

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    width: 'calc(100% / 3)',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}))

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#BBDEFB !important',
  },
})

/**
 * Interface
 */

interface TenantListSectionProps {
  shops?: Array<ShopInfo>
  onClick: (shopId: string) => void
}

type ShopInfo = Domain.Auth.ShopInfo

export default function TenantListSection(props: TenantListSectionProps) {
  return (
    <SectionPanel header={<Typography variant="h4">テナント一覧</Typography>}>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>アカウント名</TableCell>
              <TableCell>アカウントID</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {props.shops &&
              props.shops.map((shop: Domain.Auth.ShopInfo, index: number) => {
                const { shopId, shopName } = shop
                return (
                  <StyledTableRow hover key={index} onClick={() => props.onClick(shopId)}>
                    <TableCell scope="row">{shopName}</TableCell>
                    <TableCell>{shopId}</TableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </Box>
    </SectionPanel>
  )
}
