import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { styled } from '@mui/material/styles'
import { Box, CommonButton, Typography } from 'components/atoms'
import { LoadingPanel, PageHeader } from 'components/molecules'
import { ShopInformation } from 'core/domain/shopInformation'
import { useForm } from 'react-hook-form'
import { shopInformationSchemas } from 'schemas/shopInformationSchemas'
import TextInputField from './TextInputField'
import { Portal } from 'components/organisms'
import { ConfirmModal } from 'components/organisms'
import { useAppDispatch } from 'libs/hooks'
import { clearIsSaveSuccess } from 'core/modules/shopInformation'

export const Week = {
  monday: '月',
  tuesday: '火',
  wednesday: '水',
  thursday: '木',
  friday: '金',
  saturday: '土',
  sunday: '日',
  holiday: '祝',
} as const

export type Week = (typeof Week)[keyof typeof Week]

const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(106)})`,
  marginLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '6px',
  flexWrap: 'wrap',
})

const SaveButton = styled(CommonButton)({
  width: 'fit-content',
  margin: '20px 120px 20px auto',
})

/**
 * interface
 */

interface AdminPharmacyPageProps {
  shopInformation?: ShopInformation
  isSaveSuccess?: boolean
  isLoading: boolean
  onSave: (shopInfo: ShopInformation) => void
}

export default function AdminPharmacyPage(props: AdminPharmacyPageProps) {
  const { shopInformation, isSaveSuccess, isLoading, onSave } = props
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ShopInformation>({
    resolver: yupResolver(shopInformationSchemas),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  return (
    <Box>
      <PageHeader>
        <Typography variant="h1" component="h2">
          店舗情報
        </Typography>
      </PageHeader>
      {shopInformation == null ? (
        <LoadingPanel />
      ) : (
        <StyledBox>
          <Typography marginBottom="16px" variant="h3" component="h4">
            お薬受取時間
          </Typography>
          <Typography marginBottom="16px">(入力例) 08:30-20:30, 08:30-12:30/15:00-18:30, 休, 空欄可</Typography>
          <Row>
            {Object.entries(Week).map(([key, label]) => {
              const weekKey = key as keyof typeof Week
              return (
                <TextInputField
                  key={key}
                  label={label}
                  placeholder={label === Week.monday ? '例) 08:30-20:30' : ''}
                  defaultValue={shopInformation.available?.[weekKey]}
                  error={errors.available?.[weekKey]}
                  register={register(`available.${weekKey}`, { required: false })}
                />
              )
            })}
          </Row>
          <Typography marginY="16px" variant="h3" component="h4">
            ドラッグストア営業時間
          </Typography>
          <Row>
            {Object.entries(Week).map(([key, value]) => {
              const weekKey = key as keyof typeof Week
              const placeholder = weekKey == 'monday' ? '例) 08:30-20:30' : ''
              return (
                <TextInputField
                  key={key}
                  label={value}
                  placeholder={placeholder}
                  defaultValue={shopInformation.opening?.[weekKey]}
                  error={errors.opening?.[weekKey]}
                  register={register(`opening.${weekKey}`, { required: false })}
                />
              )
            })}
          </Row>
          <Typography marginBottom="16px" marginTop="20px" variant="h3" component="h4">
            コメント
          </Typography>
          <TextInputField
            placeholder="例) 第2土休, 空欄可"
            defaultValue={shopInformation.comment}
            error={errors.comment}
            register={register('comment', { required: false })}
            width="600px"
          />
          <SaveButton
            type={'primary'}
            label={isLoading ? '読み込み中...' : '保存'}
            click={handleSubmit((values: ShopInformation) => onSave(values))}
            disabled={!isValid || isLoading}
          />
        </StyledBox>
      )}
      <Portal targetId={'modal'}>
        <ConfirmModal
          isOpened={isSaveSuccess != null}
          description={isSaveSuccess ? '保存しました' : '保存に失敗しました'}
          onClickDone={() => {
            dispatch(clearIsSaveSuccess())
          }}
        />
      </Portal>
    </Box>
  )
}
