import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { store } from 'core/store'
import { addMessages } from 'core/modules/messages'

function didCallSetSendPutMessagesApi(dispatch: Dispatch) {
  const messageList = store.getState().messages.messages.messageList
  const cloneArr = [...messageList]
  cloneArr.pop()
  dispatch(addMessages([...cloneArr]))
}

export function sendPutMessages(idToken: string | undefined, shopId: string | null, id: number) {
  return async (dispatch: Dispatch) => {
    // Send POST API
    await Api.callApi(
      Api.HttpMethod.post,
      `shops/${shopId}/messages/${id}/cancel`,
      idToken,
      null,
      dispatch,
      didCallSetSendPutMessagesApi,
    )
  }
}
