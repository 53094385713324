/* eslint-disable */
import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setMessageTemplates } from 'core/modules/messageTemplates'
import { DEFAULT_MESSAGE_TEMPLATE } from 'constants/value'
import { MessageTemplates } from 'core/modules'

type ApiResponse = { messageTemplates: Domain.MedicationFollowUp.MessageTemplate[] | [] }

function didCallSetSendGetMessageTemplatesApi(dispatch: Dispatch, data: ApiResponse | null | Error) {
  if (data instanceof Error) {
    return
  }
  const initialData = [DEFAULT_MESSAGE_TEMPLATE]

  if (data && data?.messageTemplates.length > 0) {
    dispatch(setMessageTemplates([...initialData, ...data.messageTemplates]))
  } else {
    dispatch(setMessageTemplates(initialData))
  }
}

export function sendGetMessageTemplates({token, shopId}: {token: string | undefined, shopId: string | null}, options: {includeHQ?: boolean} = {}) {
  const searchParams = new URLSearchParams()
  for (const [option, available] of Object.entries(options)) {
    if (!available) continue
    searchParams.append(option, '')
  }
  const params = searchParams.toString()

  return async (dispatch: Dispatch) => {
    const path = `shops/${shopId}/message-templates?${params}`
    // Send GET API
    await Api.callApi(
      Api.HttpMethod.get,
      path,
      token,
      null,
      dispatch,
      didCallSetSendGetMessageTemplatesApi,
    )
  }
}
