import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setPatients } from 'core/modules/patients'

type Patients = Domain.Patients.Patients

function didCallSetPatientsApi(dispatch: Dispatch, data: Patients | Error) {
  if (data instanceof Error) {
    return
  }
  dispatch(setPatients(data))
}

export function sendGetPatients(idToken: string | undefined, patientLineId: string, shopId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET API
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/patients/${patientLineId}`,
      idToken,
      null,
      dispatch,
      didCallSetPatientsApi,
    )
  }
}
