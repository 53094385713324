// See https://material.io/design/color/the-color-system.html#tools-for-picking-colors for more about color system.

/**
 * Black / White
 */
const TOMODS_WHITE = '#ffffff'
const TOMODS_BLACK = '#000000'
const TOMODS_DARK = '#4e4e4e4d'

/**
 * Brand
 */

const TOMODS_BRAND_50 = '#e2f0fc' // Alternate background
const TOMODS_BRAND_100 = '#b8daf9' // Base background
const TOMODS_BRAND_200 = '#8bc4f6'
const TOMODS_BRAND_300 = '#5cacf2'
const TOMODS_BRAND_400 = '#379bf0' // Light
const TOMODS_BRAND_500 = '#058bed'
const TOMODS_BRAND_600 = '#097ddf'
const TOMODS_BRAND_700 = '#0b79d0'
const TOMODS_BRAND_800 = '#075bba' // Main
const TOMODS_BRAND_900 = '#033d9b' // Dark

/**
 * Gray
 */

const TOMODS_GRAY_50 = '#fcfcfc' // Alternate background
const TOMODS_GRAY_100 = '#f7f7f7' // Base background
const TOMODS_GRAY_200 = '#f2f2f2'
const TOMODS_GRAY_300 = '#ececec'
const TOMODS_GRAY_400 = '#cacaca'
const TOMODS_GRAY_500 = '#acacac' // Disable text
const TOMODS_GRAY_600 = '#828282' // Placeholder text
const TOMODS_GRAY_700 = '#6e6e6e' // Text content
const TOMODS_GRAY_800 = '#4e4e4e' // Text content
const TOMODS_GRAY_900 = '#2c2c2c' // Text content

/**
 * Navy
 */

const TOMODS_NAVY_50 = '#eaecff' // Alternate background
const TOMODS_NAVY_100 = '#c9d4ee' // Base background
const TOMODS_NAVY_200 = '#adb7d5'
const TOMODS_NAVY_300 = '#8f9bbd'
const TOMODS_NAVY_400 = '#7985aa'
const TOMODS_NAVY_500 = '#627198' // Disable text
const TOMODS_NAVY_600 = '#546387' // Placeholder text
const TOMODS_NAVY_700 = '#435070' // Text content
const TOMODS_NAVY_800 = '#333e5a' // Text content
const TOMODS_NAVY_900 = '#202a42' // Text content

/**
 * Semantic colors
 *
 * - BLUE: Informative
 * - RED: Negative
 * - ORANGE: Notice
 * - GREEN: Positive
 */

/* BLUE */
const TOMODS_BLUE_50 = '#e2f0fc' // Alternate background
const TOMODS_BLUE_100 = '#b8daf9' // Base background
const TOMODS_BLUE_200 = '#8bc4f6'
const TOMODS_BLUE_300 = '#5cacf2'
const TOMODS_BLUE_400 = '#379bf0' // Light
const TOMODS_BLUE_500 = '#058bed'
const TOMODS_BLUE_600 = '#097ddf'
const TOMODS_BLUE_700 = '#0b79d0'
const TOMODS_BLUE_800 = '#075bba' // Main
const TOMODS_BLUE_900 = '#033d9b' // Dark

/* RED */
const TOMODS_RED_50 = '#ffeaed' // Alternate background
const TOMODS_RED_100 = '#ffcacf' // Base background
const TOMODS_RED_200 = '#f09594'
const TOMODS_RED_300 = '#e66c6b'
const TOMODS_RED_400 = '#ef4946'
const TOMODS_RED_500 = '#f33529' // Light
const TOMODS_RED_600 = '#e42929'
const TOMODS_RED_700 = '#d21d23'
const TOMODS_RED_800 = '#c6131c' // Main
const TOMODS_RED_900 = '#b7000e' // Dark

/* ORANGE */
const TOMODS_ORANGE_50 = '#fef4e2' // Alternate background
const TOMODS_ORANGE_100 = '#fde2b6' // Base background
const TOMODS_ORANGE_200 = '#fcd087'
const TOMODS_ORANGE_300 = '#fbbc58'
const TOMODS_ORANGE_400 = '#faae38'
const TOMODS_ORANGE_500 = '#faa023' // Light
const TOMODS_ORANGE_600 = '#f59420'
const TOMODS_ORANGE_700 = '#ef851c'
const TOMODS_ORANGE_800 = '#e97619' // Main
const TOMODS_ORANGE_900 = '#df5f15' // Dark

/* GREEN */
const TOMODS_GREEN_50 = '#e1f3ef' // Alternate background
const TOMODS_GREEN_100 = '#b5e2d6' // Base background
const TOMODS_GREEN_200 = '#87d0bc'
const TOMODS_GREEN_300 = '#37ae8f'
const TOMODS_GREEN_400 = '#37ae8f'
const TOMODS_GREEN_500 = '#219f7d' // Light
const TOMODS_GREEN_600 = '#1d9171'
const TOMODS_GREEN_700 = '#188162'
const TOMODS_GREEN_800 = '#117154' // Main
const TOMODS_GREEN_900 = '#065539' // Dark

export {
  TOMODS_BRAND_50,
  TOMODS_BRAND_100,
  TOMODS_BRAND_200,
  TOMODS_BRAND_300,
  TOMODS_BRAND_400,
  TOMODS_BRAND_500,
  TOMODS_BRAND_600,
  TOMODS_BRAND_700,
  TOMODS_BRAND_800,
  TOMODS_BRAND_900,
  TOMODS_WHITE,
  TOMODS_BLACK,
  TOMODS_DARK,
  TOMODS_GRAY_50,
  TOMODS_GRAY_100,
  TOMODS_GRAY_200,
  TOMODS_GRAY_300,
  TOMODS_GRAY_400,
  TOMODS_GRAY_500,
  TOMODS_GRAY_600,
  TOMODS_GRAY_700,
  TOMODS_GRAY_800,
  TOMODS_GRAY_900,
  TOMODS_NAVY_50,
  TOMODS_NAVY_100,
  TOMODS_NAVY_200,
  TOMODS_NAVY_300,
  TOMODS_NAVY_400,
  TOMODS_NAVY_500,
  TOMODS_NAVY_600,
  TOMODS_NAVY_700,
  TOMODS_NAVY_800,
  TOMODS_NAVY_900,
  TOMODS_BLUE_50,
  TOMODS_BLUE_100,
  TOMODS_BLUE_200,
  TOMODS_BLUE_300,
  TOMODS_BLUE_400,
  TOMODS_BLUE_500,
  TOMODS_BLUE_600,
  TOMODS_BLUE_700,
  TOMODS_BLUE_800,
  TOMODS_BLUE_900,
  TOMODS_RED_50,
  TOMODS_RED_100,
  TOMODS_RED_200,
  TOMODS_RED_300,
  TOMODS_RED_400,
  TOMODS_RED_500,
  TOMODS_RED_600,
  TOMODS_RED_700,
  TOMODS_RED_800,
  TOMODS_RED_900,
  TOMODS_ORANGE_50,
  TOMODS_ORANGE_100,
  TOMODS_ORANGE_200,
  TOMODS_ORANGE_300,
  TOMODS_ORANGE_400,
  TOMODS_ORANGE_500,
  TOMODS_ORANGE_600,
  TOMODS_ORANGE_700,
  TOMODS_ORANGE_800,
  TOMODS_ORANGE_900,
  TOMODS_GREEN_50,
  TOMODS_GREEN_100,
  TOMODS_GREEN_200,
  TOMODS_GREEN_300,
  TOMODS_GREEN_400,
  TOMODS_GREEN_500,
  TOMODS_GREEN_600,
  TOMODS_GREEN_700,
  TOMODS_GREEN_800,
  TOMODS_GREEN_900,
}
