import useReactRouter from 'use-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'core/store'
import { useAuth } from 'libs/hooks/useAuth'
import { useAppNavigate } from 'libs/hooks/useNavigate'
import { usePrescriptions } from 'libs/hooks/usePrescriptions'
import { useViewer } from 'libs/hooks/useViewer'
import { useNotification } from 'libs/hooks/useNotification'
import { useNotCompatibleNotification } from './useNotCompatibleNotification'

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export {
  useReactRouter,
  useAuth,
  useAppNavigate,
  usePrescriptions,
  useViewer,
  useNotification,
  useNotCompatibleNotification,
}
