import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from 'components/atoms'

/**
 * Styles
 */
const BannerType = {
  error: 'error',
  notification: 'notification',
  notcompatible: 'notcompatible',
  medicationfollowup: 'medicationfollowup',
  medicationfollowupmessage: 'medicationfollowupmessage',
} as const
export type BannerType = (typeof BannerType)[keyof typeof BannerType]

const Banner = styled(Box)<{ type: BannerType; isOpen: boolean }>(({ type, isOpen }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FFFFFF',
  transitionDuration: '0.1s',
  overflow: 'hidden',
  willChange: 'height',
  height: isOpen ? bannerHeight : 0,
  ...(type === BannerType.error && { background: '#E74C3C' }),
  ...(type === BannerType.notification && { background: '#27AE60' }),
  ...(type === BannerType.notcompatible && { background: '#F44011' }),
  ...(type === BannerType.medicationfollowup && { background: '#E67E22' }),
  ...(type === BannerType.medicationfollowupmessage && { background: '#E67E22' }),
}))

export const bannerHeight = 48

/**
 * Interface
 */

interface BannersProps {
  hasNotification: boolean
  hasNotCompatible: boolean
  hasNewMedicationFollowUp: boolean
  hasNewMedicationFollowUpMessage: string | undefined
  error: Error | null
}

export default function Banners(props: BannersProps) {
  const { hasNotification, hasNotCompatible, hasNewMedicationFollowUp, hasNewMedicationFollowUpMessage, error } = props

  return (
    <Box>
      <Banner type={BannerType.error} isOpen={error != null}>
        <Typography variant="caption">{error ? error.message : ''}</Typography>
      </Banner>
      <Banner type={BannerType.notification} isOpen={hasNotification}>
        <Typography variant="caption">
          {'新しい処方せんが届いています　一覧画面で更新ボタンを押してください'}
        </Typography>
      </Banner>
      <Banner type={BannerType.notcompatible} isOpen={hasNotCompatible}>
        <Typography variant="caption">
          {'確認していない処方せんがあります　一覧画面で青色の背景になっている処方せんをご確認ください'}
        </Typography>
      </Banner>
      <Banner type={BannerType.medicationfollowup} isOpen={hasNewMedicationFollowUp}>
        <Typography variant="caption">
          {'服薬フォロー登録されました　服薬フォロー中の処方せん一覧画面で更新ボタンを押してください'}
        </Typography>
      </Banner>
      <Banner type={BannerType.medicationfollowupmessage} isOpen={!!hasNewMedicationFollowUpMessage}>
        <Typography variant="caption">
          {`${hasNewMedicationFollowUpMessage}さんから新しいメッセージが届いています　一覧画面で更新ボタンを押してください`}
        </Typography>
      </Banner>
    </Box>
  )
}
