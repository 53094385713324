import { createAction, createReducer } from '@reduxjs/toolkit'

type State = {
  hasNewMedicationFollowUpMessageNotification: string | undefined
}

const initialState = { hasNewMedicationFollowUpMessageNotification: undefined }

export function createInitialState(): State {
  return initialState
}

const SET_HAS_NEW_MEDICATION_FOLLOW_UP_MESSAGE_NOTIFICATION =
  'has-new-medication-follow-up-message-notification/set' as const
const CLEAR_HAS_NEW_MEDICATION_FOLLOW_UP_MESSAGE_NOTIFICATION =
  'has-new-medication-follow-up-message-notification/clear' as const

export const setHasNewMedicationFollowUpMessageNotification = createAction<string | undefined>(
  SET_HAS_NEW_MEDICATION_FOLLOW_UP_MESSAGE_NOTIFICATION,
)
export const clearHasNewMedicationFollowUpMessageNotification = createAction(
  CLEAR_HAS_NEW_MEDICATION_FOLLOW_UP_MESSAGE_NOTIFICATION,
)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setHasNewMedicationFollowUpMessageNotification, (state, action) => {
      state.hasNewMedicationFollowUpMessageNotification = action.payload
    })
    .addCase(clearHasNewMedicationFollowUpMessageNotification, state => {
      state.hasNewMedicationFollowUpMessageNotification = initialState.hasNewMedicationFollowUpMessageNotification
    })
    .addDefaultCase(() => {
      return
    })
})
