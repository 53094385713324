import { PaletteOptions } from '@mui/material'
import { COLORS } from 'constants/index'

const pallets: PaletteOptions = {
  primary: {
    light: COLORS.TOMODS_BLUE_600,
    main: COLORS.TOMODS_BLUE_700,
    dark: COLORS.TOMODS_BLUE_800,
    contrastText: COLORS.TOMODS_WHITE,
  },
  secondary: {
    light: COLORS.TOMODS_GRAY_600,
    main: COLORS.TOMODS_GRAY_700,
    dark: COLORS.TOMODS_DARK,
    contrastText: COLORS.TOMODS_GRAY_800,
  },
  default: {
    50: COLORS.TOMODS_GRAY_50,
    100: COLORS.TOMODS_GRAY_100,
    200: COLORS.TOMODS_GRAY_200,
    300: COLORS.TOMODS_GRAY_300,
    400: COLORS.TOMODS_GRAY_400,
    500: COLORS.TOMODS_GRAY_500,
    600: COLORS.TOMODS_GRAY_600,
    700: COLORS.TOMODS_GRAY_700,
    800: COLORS.TOMODS_GRAY_800,
    900: COLORS.TOMODS_GRAY_900,
  },
  brand: {
    50: COLORS.TOMODS_BRAND_50,
    100: COLORS.TOMODS_BRAND_100,
    200: COLORS.TOMODS_BRAND_200,
    300: COLORS.TOMODS_BRAND_300,
    400: COLORS.TOMODS_BRAND_400,
    500: COLORS.TOMODS_BRAND_500,
    600: COLORS.TOMODS_BRAND_600,
    700: COLORS.TOMODS_BRAND_700,
    800: COLORS.TOMODS_BRAND_800,
    900: COLORS.TOMODS_BRAND_900,
  },
  informative: {
    50: COLORS.TOMODS_BLUE_50,
    100: COLORS.TOMODS_BLUE_100,
    200: COLORS.TOMODS_BLUE_200,
    300: COLORS.TOMODS_BLUE_300,
    400: COLORS.TOMODS_BLUE_400,
    500: COLORS.TOMODS_BLUE_500,
    600: COLORS.TOMODS_BLUE_600,
    700: COLORS.TOMODS_BLUE_700,
    800: COLORS.TOMODS_BLUE_800,
    900: COLORS.TOMODS_BLUE_900,
  },
  negative: {
    50: COLORS.TOMODS_RED_50,
    100: COLORS.TOMODS_RED_100,
    200: COLORS.TOMODS_RED_200,
    300: COLORS.TOMODS_RED_300,
    400: COLORS.TOMODS_RED_400,
    500: COLORS.TOMODS_RED_500,
    600: COLORS.TOMODS_RED_600,
    700: COLORS.TOMODS_RED_700,
    800: COLORS.TOMODS_RED_800,
    900: COLORS.TOMODS_RED_900,
  },
  notice: {
    50: COLORS.TOMODS_ORANGE_50,
    100: COLORS.TOMODS_ORANGE_100,
    200: COLORS.TOMODS_ORANGE_200,
    300: COLORS.TOMODS_ORANGE_300,
    400: COLORS.TOMODS_ORANGE_400,
    500: COLORS.TOMODS_ORANGE_500,
    600: COLORS.TOMODS_ORANGE_600,
    700: COLORS.TOMODS_ORANGE_700,
    800: COLORS.TOMODS_ORANGE_800,
    900: COLORS.TOMODS_ORANGE_900,
  },
  positive: {
    50: COLORS.TOMODS_GREEN_50,
    100: COLORS.TOMODS_GREEN_100,
    200: COLORS.TOMODS_GREEN_200,
    300: COLORS.TOMODS_GREEN_300,
    400: COLORS.TOMODS_GREEN_400,
    500: COLORS.TOMODS_GREEN_500,
    600: COLORS.TOMODS_GREEN_600,
    700: COLORS.TOMODS_GREEN_700,
    800: COLORS.TOMODS_GREEN_800,
    900: COLORS.TOMODS_GREEN_900,
  },
  grey: {
    50: COLORS.TOMODS_GRAY_50,
    100: COLORS.TOMODS_GRAY_100,
    200: COLORS.TOMODS_GRAY_200,
    300: COLORS.TOMODS_GRAY_300,
    400: COLORS.TOMODS_GRAY_400,
    500: COLORS.TOMODS_GRAY_500,
    600: COLORS.TOMODS_GRAY_600,
    700: COLORS.TOMODS_GRAY_700,
    800: COLORS.TOMODS_GRAY_800,
    900: COLORS.TOMODS_GRAY_900,
  },
  text: {
    primary: COLORS.TOMODS_BLACK,
    secondary: COLORS.TOMODS_BLACK,
    disabled: COLORS.TOMODS_WHITE,
    hint: COLORS.TOMODS_GRAY_600,
  },
}

export default pallets
