import React, { useEffect } from 'react'
import { hooks } from 'libs'
import { Usecases } from 'core'
import { usePrescriptions } from 'libs/hooks/usePrescriptions'
import InProcessPrescriptionsPage from 'components/screens/InProcessPrescriptionsPage'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { usePlayNotCompatibleNotification } from 'libs/hooks/usePlayNotCompatibleNotification'
import { clearInProcessPrescriptions } from 'core/modules/inProcessPrescriptions'
import { setHasNewMedicationFollowUpMessageNotification } from 'core/modules/hasNewMedicationFollowUpMessageNotification'

/**
 * Interface
 */

export default function InProcessPrescriptionsPageContainer() {
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const dispatch = useAppDispatch()
  const token = hooks.useAuth().idToken
  const { filteredInProcessPrescriptions, setFilterForInProcess, setSortOrderForInProcess, displayConditions } =
    usePrescriptions()
  const { playNotCompatible, cleanUpNotCompatibleFunc } = usePlayNotCompatibleNotification()
  const { startCheckCb, stopCheckCb } = hooks.useNotCompatibleNotification({
    playNoCompatibleNotification: playNotCompatible,
  })

  useEffect(() => {
    if (filteredInProcessPrescriptions == null) return
    // isRead が一つでもあるかチェックする
    const check = filteredInProcessPrescriptions.some(value => value.isRead === false)
    if (check === true) {
      // 未読が一つでもあればチェックスタート
      startCheckCb()
    } else {
      // 未読がなければ止める
      stopCheckCb()
      cleanUpNotCompatibleFunc()
    }

    return () => {
      stopCheckCb()
      cleanUpNotCompatibleFunc()
    }
  }, [filteredInProcessPrescriptions, stopCheckCb, cleanUpNotCompatibleFunc])

  const handleFilterChange = (filter: string) => {
    setFilterForInProcess(filter)
  }

  const handleOrderChange = (newSortKey: string) => {
    setSortOrderForInProcess(newSortKey)
  }

  const handlePrescriptionsUpdate = () => {
    if (!shopId) return
    // 処理中の処方せん一覧更新時に新着メッセージの存在フラグを落とす（通知バナーを非表示）
    dispatch(setHasNewMedicationFollowUpMessageNotification(undefined))
    dispatch(clearInProcessPrescriptions())
    dispatch(Usecases.getInProcessPrescriptions.sendGetInProcessPrescriptions(token, shopId))
    dispatch(Usecases.getDonePrescriptions.sendGetDonePrescriptions(token, shopId))
  }

  return (
    <InProcessPrescriptionsPage
      shopId={shopId}
      prescriptions={filteredInProcessPrescriptions}
      filter={displayConditions.filterForInProcess}
      sortOrder={displayConditions.sortOrderForInProcess}
      handleFilterChange={(filter: string) => handleFilterChange(filter)}
      handleOrderChange={(sortKey: string) => handleOrderChange(sortKey)}
      handlePrescriptionsUpdate={() => {
        handlePrescriptionsUpdate()
      }}
    />
  )
}
