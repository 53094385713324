import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setPatients } from 'core/modules/patients'
import { Options } from 'libs/api'

type Patients = Domain.Patients.Patients

function didCallSetPatientsApi(dispatch: Dispatch, data: Patients | Error, options: Options | undefined) {
  if (data instanceof Error) {
    return
  }
  if (options?.payload === undefined) return
  dispatch(setPatients(options.payload))
}

export function sendPutPatients(idToken: string | undefined, patientLineId: string | undefined, shopId: string | undefined, payload: Patients) {
  return async (dispatch: Dispatch) => {
    // Send PUT API
    await Api.callApi(
      Api.HttpMethod.put,
      `shops/${shopId}/patients/${patientLineId}`,
      idToken,
      JSON.stringify({ remarks: payload.remarks }),
      dispatch,
      didCallSetPatientsApi,
      { payload: payload }
    )
  }
}
