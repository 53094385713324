import { Domain } from 'core'
import {
  MEDICATION_FOLLOW_UP_MESSAGE_STATUS as STATUS,
  MEDICATION_FOLLOW_UP_ORDER_KEY_NAME as ORDER_KEY_NAME,
  MEDICATION_FOLLOW_UP_LAST_MESSAGE_SENDER as LAST_MESSAGE_SENDER,
} from 'constants/text'
import { StatusFilter } from './usePrescriptions'
import { SortKey, SortOrder } from 'core/domain/prescription'

export type PrescriptionWithMedicationFollowUpStatusType = Domain.Prescription.Prescription &
  Domain.MedicationFollowUp.MedicationFollowUpMessage
type compareFunc = (
  a: PrescriptionWithMedicationFollowUpStatusType,
  b: PrescriptionWithMedicationFollowUpStatusType,
) => number
export const FILTER_INITIAL_STATUS_ALL = STATUS.ALL.filterName
export const ORDER_INITIAL_KEY_ACCEPT_CODE: SortKey = 'acceptedCode'

export interface PrescriptionsHeaderItem {
  label: string
  sortKey: string
  compare: (a: PrescriptionWithMedicationFollowUpStatusType, b: PrescriptionWithMedicationFollowUpStatusType) => number
  reverseCompare: (
    a: PrescriptionWithMedicationFollowUpStatusType,
    b: PrescriptionWithMedicationFollowUpStatusType,
  ) => number
}

export const STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: STATUS.ALL.label,
    filter: STATUS.ALL.filterName,
  },
  {
    label: STATUS.UNTREATED.label,
    filter: STATUS.UNTREATED.filterName,
  },
  {
    label: STATUS.SEND_RESERVED.label,
    filter: STATUS.SEND_RESERVED.filterName,
  },
  {
    label: STATUS.UNREAD.label,
    filter: STATUS.UNREAD.filterName,
  },
  {
    label: STATUS.READ.label,
    filter: STATUS.READ.filterName,
  },
]

/** ベースソート処理 */
const compareBase = (a: string | number, b: string | number) => {
  let comparison = 0
  if (a > b) {
    comparison = 1
  } else if (a < b) {
    comparison = -1
  }
  return comparison
}

/** リバースソート処理 */
const reverseCompareBase = (a: string | number, b: string | number) => {
  let comparison = 0
  if (a > b) {
    comparison = -1
  } else if (a < b) {
    comparison = 1
  }
  return comparison
}

/** 受付番号のソート */
export const compareAcceptedCode: compareFunc = (a, b) => compareBase(Number(a.acceptedCode), Number(b.acceptedCode))
/** 受付番号のリバースソート */
export const reverseCompareAcceptedCode: compareFunc = (a, b) =>
  reverseCompareBase(Number(a.acceptedCode), Number(b.acceptedCode))

/** 受付時間のソート */
export const compareAcceptedAt: compareFunc = (a, b) => compareBase(a.acceptedAt, b.acceptedAt)
/** 受付時間のリバースソート */
export const reverseCompareAcceptedAt: compareFunc = (a, b) => reverseCompareBase(a.acceptedAt, b.acceptedAt)

/** 名前のソート */
export const compareName: compareFunc = (a, b) => compareBase(a.name, b.name)
/** 名前のリバースソート */
export const reverseCompareName: compareFunc = (a, b) => reverseCompareBase(a.name, b.name)

/** 処方せん画像枚数のソート */
export const compareImageCount: compareFunc = (a, b) => compareBase(a.imageCount, b.imageCount)
/** 処方せん画像枚数のリバースソート */
const reverseCompareImageCount: compareFunc = (a, b) => reverseCompareBase(a.imageCount, b.imageCount)

/** チェック項目のソート */
const compareCheckList: compareFunc = (a, b) => {
  const checkListA = [a.isGenericMedicine, a.hasPrescriptionNote, a.isPregnancy, a.isBreastFeeding, !!a.allergy].filter(
    check => check,
  ).length
  const checkListB = [b.isGenericMedicine, b.hasPrescriptionNote, b.isPregnancy, b.isBreastFeeding, !!b.allergy].filter(
    check => check,
  ).length

  return compareBase(checkListA, checkListB)
}
/** チェック項目のリバースソート */
const reverseCompareCheckList: compareFunc = (a, b) => {
  const checkListA = [a.isGenericMedicine, a.hasPrescriptionNote, a.isPregnancy, a.isBreastFeeding, !!a.allergy].filter(
    check => check,
  ).length
  const checkListB = [b.isGenericMedicine, b.hasPrescriptionNote, b.isPregnancy, b.isBreastFeeding, !!b.allergy].filter(
    check => check,
  ).length

  return reverseCompareBase(checkListA, checkListB)
}

/** 患者側メッセージ未読ステータスのソート */
export const compareMessageUnreadStatus = (
  a: PrescriptionWithMedicationFollowUpStatusType,
  b: PrescriptionWithMedicationFollowUpStatusType,
): number => {
  let comparison = 0
  if (a.isMessageUnreadByUser < b.isMessageUnreadByUser) {
    comparison = 1
  } else if (a.isMessageUnreadByUser > b.isMessageUnreadByUser) {
    comparison = -1
  }
  return comparison
}
/** 患者側メッセージ未読ステータスのリバースソート */
export const reverseCompareMessageUnreadStatus = (
  a: PrescriptionWithMedicationFollowUpStatusType,
  b: PrescriptionWithMedicationFollowUpStatusType,
): number => {
  let comparison = 0
  if (a.isMessageUnreadByUser > b.isMessageUnreadByUser) {
    comparison = 1
  } else if (a.isMessageUnreadByUser < b.isMessageUnreadByUser) {
    comparison = -1
  }
  return comparison
}

/** 患者側メッセージ未読ステータスのソート */
export const compareMessageStartStatus = (
  a: PrescriptionWithMedicationFollowUpStatusType,
  b: PrescriptionWithMedicationFollowUpStatusType,
): number => {
  let comparison = 0
  if (a.isStartedMessage > b.isStartedMessage) {
    comparison = 1
  } else if (a.isStartedMessage < b.isStartedMessage) {
    comparison = -1
  }
  return comparison
}
/** メッセージステータスのリバースソート */
export const reverseCompareMessageStartStatus = (
  a: PrescriptionWithMedicationFollowUpStatusType,
  b: PrescriptionWithMedicationFollowUpStatusType,
): number => {
  let comparison = 0
  if (a.isStartedMessage < b.isStartedMessage) {
    comparison = 1
  } else if (a.isStartedMessage > b.isStartedMessage) {
    comparison = -1
  }
  return comparison
}

export const PRESCRIPTIONS_HEADER_ITEMS: {
  label: string
  sortKey: string
  compare: compareFunc
  reverseCompare: compareFunc
}[] = [
  {
    label: ORDER_KEY_NAME.ACCEPT_CODE.label,
    sortKey: ORDER_KEY_NAME.ACCEPT_CODE.sortName,
    compare: compareAcceptedCode,
    reverseCompare: reverseCompareAcceptedCode,
  },
  {
    label: ORDER_KEY_NAME.ACCEPT_AT.label,
    sortKey: ORDER_KEY_NAME.ACCEPT_AT.sortName,
    compare: compareAcceptedAt,
    reverseCompare: reverseCompareAcceptedAt,
  },
  {
    label: ORDER_KEY_NAME.NAME.label,
    sortKey: ORDER_KEY_NAME.NAME.sortName,
    compare: compareName,
    reverseCompare: reverseCompareName,
  },
  {
    label: ORDER_KEY_NAME.IMAGE_COUNT.label,
    sortKey: ORDER_KEY_NAME.IMAGE_COUNT.sortName,
    compare: compareImageCount,
    reverseCompare: reverseCompareImageCount,
  },
  {
    label: ORDER_KEY_NAME.CHECK_LIST.label,
    sortKey: ORDER_KEY_NAME.CHECK_LIST.sortName,
    compare: compareCheckList,
    reverseCompare: reverseCompareCheckList,
  },
  {
    label: ORDER_KEY_NAME.MESSAGE_STATUS.label,
    sortKey: ORDER_KEY_NAME.MESSAGE_STATUS.sortName,
    compare: compareMessageStartStatus,
    reverseCompare: reverseCompareMessageStartStatus,
  },
]

export const sortPrescriptions = (
  prescriptions: Array<PrescriptionWithMedicationFollowUpStatusType>,
  sortKey: SortOrder,
): Array<PrescriptionWithMedicationFollowUpStatusType> => {
  const sortPrescriptionsData = [...prescriptions]
  PRESCRIPTIONS_HEADER_ITEMS.map(item => {
    if (sortKey.sortKey === item.sortKey) return compare(sortPrescriptionsData, item, sortKey.order)
    if (sortKey.sortKey === ORDER_KEY_NAME.MESSAGE_STATUS.sortName)
      return messageCompare(sortPrescriptionsData, sortKey.order)
  })
  return sortPrescriptionsData
}

export const isDescOrder = (order: string): boolean => {
  return order === 'desc'
}
const compare = (
  prescriptions: Array<PrescriptionWithMedicationFollowUpStatusType>,
  sortKey: PrescriptionsHeaderItem,
  order: string,
): Array<PrescriptionWithMedicationFollowUpStatusType> => {
  return prescriptions.sort((a, b) => {
    if (isDescOrder(order)) return sortKey.reverseCompare(a, b)
    return sortKey.compare(a, b)
  })
}

const messageCompare = (
  prescriptions: Array<PrescriptionWithMedicationFollowUpStatusType>,
  order: string,
): Array<PrescriptionWithMedicationFollowUpStatusType> => {
  const sortPrescriptions = prescriptions.sort((a, b) => {
    if (isDescOrder(order)) return reverseCompareMessageUnreadStatus(a, b)
    return compareMessageUnreadStatus(a, b)
  })

  return sortPrescriptions.sort((a, b) => {
    if (isDescOrder(order)) return reverseCompareMessageStartStatus(a, b)
    return compareMessageStartStatus(a, b)
  })
}

export const filterNameKeyList = STATUS_FILTERS.map(obj => obj.filter)
export const filter = (
  prescriptions: Array<PrescriptionWithMedicationFollowUpStatusType>,
  type: (typeof filterNameKeyList)[number],
): Array<PrescriptionWithMedicationFollowUpStatusType> => {
  if (type === STATUS.ALL.filterName) return prescriptions
  const filterPrescriptions: Array<PrescriptionWithMedicationFollowUpStatusType> = []
  prescriptions.map(data => {
    // 未処理 メッセージが開始されてない または メッセージ最終送信者が「患者」
    if (
      type === STATUS.UNTREATED.filterName &&
      (!data.isStartedMessage || (data.lastMessanger === LAST_MESSAGE_SENDER.USER && !data.reservedAt))
    )
      return filterPrescriptions.push(data)
    // 送信済み予約
    if (type === STATUS.SEND_RESERVED.filterName && !!data.reservedAt) return filterPrescriptions.push(data)
    // 患者未読
    if (type === STATUS.UNREAD.filterName && data.isStartedMessage && data.isMessageUnreadByUser)
      return filterPrescriptions.push(data)
    // 患者既読
    if (type === STATUS.READ.filterName && data.isStartedMessage && !data.isMessageUnreadByUser)
      return filterPrescriptions.push(data)
  })
  // 全て
  return filterPrescriptions
}
