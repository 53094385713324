import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { Domain } from 'core'
import { SectionPanel } from 'components/molecules'
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  StatusChip,
  TextField,
  CommonButton,
} from 'components/atoms'
import icoGeneric from 'assets/images/ico_generic.svg'
import icoOriginal from 'assets/images/ico_original.svg'
import icoBook from 'assets/images/ico_book.svg'
import icoMaternity from 'assets/images/ico_maternity.svg'
import icoFeeding from 'assets/images/ico_feeding.svg'
import icoNone from 'assets/images/ico_none.svg'
import icoEdit from 'assets/images/ico_edit.svg'
import { date } from 'libs'
import * as COLORS from '../../../constants/color'
import hospitalVisitIcon from '../../../assets/images/hospital-visit.svg'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { Usecases } from 'core'
import { clearPatients } from 'core/modules/patients'
import { hooks } from 'libs'
import { MAX_REMARKS } from 'constants/value'

const Content = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(54)})`,
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
}))

const SectionHead = styled(Typography)({
  fontWeight: 'normal',
})

const StyledTable = styled(Table)({
  width: 'auto',
})

const StyledTableBody = styled(TableBody)({
  padding: 0,
})

const StyledTableRow = styled(TableRow)({
  borderWidth: 0,
})

const StyledMediumTableRow = styled(TableRow)({
  borderWidth: 0,
  height: '183px',
})

const StyledLargeTableRow = styled(TableRow)({
  borderWidth: 0,
  height: '244px',
})

const StyledTableHeader = styled(TableCell)(() => ({
  width: '124px',
  minWidth: '124px',
  height: '61px',
  borderStyle: 'solid',
  backgroundColor: COLORS.TOMODS_GRAY_300,
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
}))

const StyledTableData = styled(TableCell)<{ isFix?: boolean }>(({ isFix = false }) => ({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  width: isFix ? '380px' : 'auto',
}))

const StyledMessageStatusTableData = styled(TableCell)<{ isstartedmessage?: boolean; ismessageunreadbyuser?: boolean }>(
  ({ isstartedmessage, ismessageunreadbyuser }) => ({
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderWidth: 0,
    borderBottomWidth: 1,
    width: '380px',
    ...(isstartedmessage &&
      ismessageunreadbyuser && {
        color: '#F50057',
        fontWeight: 'bold',
      }),
  }),
)

const StyledTableRemarkerData = styled(TableCell)({
  borderWidth: 0,
  width: '980px',
  height: '125px',
  overflowY: 'scroll',
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
})

const CheckItemList = styled(TableCell)({
  flexDirection: 'column',
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  padding: 0,
})

const Icon = styled('img')({
  width: 28,
  height: 28,
})

const EditIcon = styled('img')({
  marginLeft: 16,
  width: 18,
  height: 18,
  cursor: 'pointer',
})

const EditTextBox = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  lineHeight: '18px',
})

const RemarkerTextField = styled(TextField)({
  width: '730px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  background: '#FFFFFF',
})

const RemarkerText = styled(Typography)({
  height: '90px',
  overflowY: 'auto',
})

const RemarkerBtn = styled(TableCell)({
  borderWidth: 0,
})

/**
 * Interface
 */

export interface PrescriptionInfoSectionProps {
  prescription: Prescription | null
}

type Prescription = Domain.Prescription.Prescription

export interface PrescriptionPreConfirmChecks {
  isGenericMedicine?: boolean
  hasPrescriptionNote: boolean
  isPregnancy: boolean
  isBreastFeeding: boolean
}

export const PreConfirms = {
  isGenericMedicine: { label: 'ジェネリック医薬品を希望する', icon: `${icoGeneric}` },
  hasPrescriptionNote: { label: 'おくすり手帳をもってくる', icon: `${icoBook}` },
  isPregnancy: { label: '妊娠中である', icon: `${icoMaternity}` },
  isBreastFeeding: { label: '授乳中である', icon: `${icoFeeding}` },
} as const

type PreConfirmsKey = keyof typeof PreConfirms

const checkItem = (key: PreConfirmsKey, prescription: Prescription | null) => {
  if (prescription == null) return false
  // 事前確認ページ改修タスク前は、ジェネリック医薬品を希望するかどうかをisGenericMedicineで判断していたが、改修後はmedicineDesignationで判断するようになったため、isGenericMedicineとmedicineDesignationの両方で判定するようにする。
  if (key === 'isGenericMedicine')
    return prescription.isGenericMedicine || prescription.medicineDesignation === 'Generic'
  return prescription[key]
}

export default function PrescriptionInfoSection(props: PrescriptionInfoSectionProps) {
  const { prescription } = props

  const [acceptedAt, setAcceptedAt] = useState('-')
  const [completedAt, setCompletedAt] = useState('-')
  const [isEdit, setIsEdit] = useState(false)
  const [remarks, setRemarks] = useState('')
  const dispatch = useAppDispatch()
  const shopId = useParams().shopId
  const token = hooks.useAuth().idToken

  useEffect(() => {
    if (!prescription || prescription.patientLineId === undefined || !shopId) return
    dispatch(clearPatients())
    dispatch(Usecases.getPatients.sendGetPatients(token, prescription.patientLineId, shopId))
  }, [prescription])

  const orignRemarks = useAppSelector(state => state.patients.remarks)

  useEffect(() => {
    initRemarks()
  }, [orignRemarks])

  const getVisitingText = (prescription: Prescription) => {
    const acceptDate = new Date(prescription.acceptedAt)
    // 次の日を取得したい
    const nextDate = new Date(acceptDate)
    nextDate.setDate(nextDate.getDate() + 1)
    const formattedDate = `${nextDate.getMonth() + 1}月${nextDate.getDate()}日`

    return prescription.isVisitToday ? '本日来局予定' : `${formattedDate} 以降来局`
  }

  useEffect(() => {
    if (prescription) {
      setAcceptedAt(date.formatDate(prescription.acceptedAt))
      if (prescription.completedAt) {
        setCompletedAt(date.formatDate(prescription.completedAt))
      }
    }
  }, [prescription])

  const initRemarks = () => {
    setRemarks(orignRemarks ?? '')
  }

  const getMessageStatus = (isStartedMessage?: boolean, isMessageUnreadByUser?: boolean): string => {
    if (!isStartedMessage) return 'なし'
    return isMessageUnreadByUser ? '未読' : '既読'
  }

  return (
    <Box>
      <SectionPanel header={<SectionHead variant="h6">入力内容</SectionHead>}>
        <Content paddingLeft={2} paddingRight={2} paddingBottom={2}>
          <Box display={'flex'}>
            <TableContainer sx={{ flex: 1 }}>
              <StyledTable aria-label="simple table">
                <StyledTableBody>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      受付番号
                    </StyledTableHeader>
                    <StyledTableData isFix={true} align="left">
                      {prescription?.acceptedCode}
                    </StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      受付時間
                    </StyledTableHeader>
                    <StyledTableData isFix={true} align="left">
                      {acceptedAt}
                    </StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      電話番号
                    </StyledTableHeader>
                    <StyledTableData isFix={true} align="left">
                      {prescription?.tel}
                    </StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      来局予定
                    </StyledTableHeader>
                    <StyledTableData isFix={true} align="left">
                      <Box display="flex" alignItems="center">
                        {prescription && (
                          <>
                            <Icon src={hospitalVisitIcon} />
                            <Typography
                              variant="caption"
                              color={prescription.isVisitToday ? COLORS.TOMODS_BLACK : COLORS.TOMODS_RED_800}>
                              {getVisitingText(prescription)}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </StyledTableData>
                  </StyledTableRow>
                  <StyledLargeTableRow>
                    <StyledTableHeader component="th" scope="row">
                      チェック項目
                    </StyledTableHeader>
                    <CheckItemList>
                      {Object.entries(PreConfirms).map(([key, preConfirm], index) => {
                        const preConfirmKey = key as PreConfirmsKey
                        const isChecked = checkItem(preConfirmKey, prescription)
                        return (
                          <Box key={key} borderTop={index === 0 ? 0 : 1} borderColor={'#E0E0E0'}>
                            {preConfirmKey === 'isGenericMedicine' ? (
                              <Box display="flex" alignItems="center" padding={2}>
                                {prescription && (
                                  <>
                                    <Icon src={isChecked ? icoGeneric : icoOriginal} />
                                    <Typography variant="caption">
                                      {isChecked ? 'ジェネリック医薬品' : '先発薬'}を希望する
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center" padding={2}>
                                <Icon src={isChecked ? preConfirm.icon : icoNone} />
                                {isChecked && <Typography variant="caption">{preConfirm.label}</Typography>}
                              </Box>
                            )}
                          </Box>
                        )
                      })}
                    </CheckItemList>
                  </StyledLargeTableRow>
                </StyledTableBody>
              </StyledTable>
            </TableContainer>
            <Box width={22}></Box>
            <TableContainer sx={{ flex: 1 }}>
              <StyledTable aria-label="simple table">
                <StyledTableBody>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      ステータス
                    </StyledTableHeader>
                    <StyledTableData align="left">
                      {prescription?.status ? <StatusChip status={prescription.status} /> : null}
                    </StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      メッセージ
                    </StyledTableHeader>
                    <StyledMessageStatusTableData
                      align="left"
                      isstartedmessage={prescription?.isStartedMessage}
                      ismessageunreadbyuser={prescription?.isMessageUnreadByUser}>
                        {prescription ? getMessageStatus(prescription?.isStartedMessage, prescription?.isMessageUnreadByUser) : null}
                    </StyledMessageStatusTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      完了時間
                    </StyledTableHeader>
                    <StyledTableData align="left">{completedAt}</StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      ユーザー名
                    </StyledTableHeader>
                    <StyledTableData align="left">{prescription?.name}</StyledTableData>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableHeader component="th" scope="row">
                      体重(kg)
                    </StyledTableHeader>
                    <StyledTableData align="left">{prescription?.weight}</StyledTableData>
                  </StyledTableRow>
                  <StyledMediumTableRow>
                    <StyledTableHeader component="th" scope="row">
                      アレルギー・その他連絡事項
                    </StyledTableHeader>
                    <StyledTableData align="left">{prescription?.allergy}</StyledTableData>
                  </StyledMediumTableRow>
                </StyledTableBody>
              </StyledTable>
            </TableContainer>
          </Box>
          {isEdit && (
            <Table>
              <StyledTableRow>
                <StyledTableHeader component="th" scope="row">
                  <EditTextBox>
                    備考
                    <EditIcon src={icoEdit} onClick={() => setIsEdit(true)} />
                  </EditTextBox>
                </StyledTableHeader>
                <StyledTableData colSpan={4}>
                  <Box display="flex" alignItems="center">
                    <RemarkerTextField
                      variant="outlined"
                      InputProps={{ disableUnderline: true }}
                      multiline
                      rows={4}
                      value={remarks}
                      onChange={event => {
                        event.target.value.length <= MAX_REMARKS && setRemarks(event.target.value)
                      }}></RemarkerTextField>
                    <RemarkerBtn>
                      <CommonButton
                        type={'cancelEdit'}
                        label={'キャンセル'}
                        click={() => {
                          initRemarks()
                          setIsEdit(false)
                        }}
                      />
                    </RemarkerBtn>
                    <RemarkerBtn>
                      <CommonButton
                        type={'primary'}
                        label={'保存'}
                        click={() => {
                          dispatch(
                            Usecases.setPatients.sendPutPatients(token, prescription?.patientLineId, shopId, {
                              remarks: remarks,
                            }),
                          )
                          setIsEdit(false)
                        }}
                      />
                    </RemarkerBtn>
                  </Box>
                </StyledTableData>
              </StyledTableRow>
            </Table>
          )}
          {!isEdit && (
            <Table>
              <StyledTableRow>
                <StyledTableHeader component="th" scope="row">
                  <EditTextBox>
                    備考
                    <EditIcon src={icoEdit} onClick={() => setIsEdit(true)} />
                  </EditTextBox>
                </StyledTableHeader>
                <StyledTableData colSpan={4}>
                  <StyledTableRemarkerData align="left">
                    <RemarkerText>{remarks}</RemarkerText>
                  </StyledTableRemarkerData>
                </StyledTableData>
              </StyledTableRow>
            </Table>
          )}
        </Content>
      </SectionPanel>
    </Box>
  )
}
