import React from 'react'
import { CircularProgress } from 'components/atoms'
import { CenterAlignmentTemplate } from 'components/templates'

export default function InitializePage() {
  return (
    <CenterAlignmentTemplate>
      <CircularProgress />
    </CenterAlignmentTemplate>
  )
}
