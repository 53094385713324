import { Domain } from 'core'
import { createAction, createReducer } from '@reduxjs/toolkit'

type Prescription = Domain.Prescription.Prescription & Domain.MedicationFollowUp.MedicationFollowUpMessage
type State = {
  prescriptions?: Array<Prescription>
}

export function createInitialState(): State {
  return { prescriptions: undefined }
}

const SET_IN_PROCESS_PRESCRIPTIONS = 'in-process-prescriptions/set' as const
const CLEAR_IN_PROCESS_PRESCRIPTIONS = 'in-process-prescriptions/clear' as const

export const setInProcessPrescriptions = createAction<Array<Prescription>>(SET_IN_PROCESS_PRESCRIPTIONS)
export const clearInProcessPrescriptions = createAction(CLEAR_IN_PROCESS_PRESCRIPTIONS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setInProcessPrescriptions, (state, action) => {
      state.prescriptions = action.payload
    })
    .addCase(clearInProcessPrescriptions, state => {
      state.prescriptions = undefined
    })
    .addDefaultCase(() => {
      return
    })
})
