import React, { useState, useEffect } from 'react'
import { hooks } from 'libs'
import CallbackPage from 'components/screens/CallbackPage'

/**
 * Interface
 */

interface ChildrenProps {
  authenticated: boolean
  error: Error | string | undefined
}

interface CallbackPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

/**
 * Container
 */

function CallbackPageContainer(props: CallbackPageContainerProps) {
  const { children } = props
  const { authenticated, handleAuthentication } = hooks.useAuth()
  const [error, setError] = useState<Error | string | undefined>(undefined)

  useEffect(() => {
    if (authenticated) {
      return
    }
    handleAuthentication()
      .then(() => {
        // Nothing to do
      })
      .catch(error => {
        setError(error)
      })
  }, [authenticated, handleAuthentication])

  return children({ authenticated, error })
}

export default function CallbackPageComponent() {
  return (
    <CallbackPageContainer>
      {props => {
        return <CallbackPage {...props} />
      }}
    </CallbackPageContainer>
  )
}

