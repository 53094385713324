import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { Domain } from 'core'
import { setOriginFollowUpPrescriptions } from 'core/modules/followUpPrescriptions'
import { Prescription } from 'core/domain'

type Prescription = Domain.Prescription.Prescription & Domain.MedicationFollowUp.MedicationFollowUpMessage

type PrescriptionsData = { prescriptions: Array<Prescription> }

function didCallGetMedicationFollowUpPrescriptionsApi(dispatch: Dispatch, data: PrescriptionsData | Error) {
  if (data instanceof Error) {
    return
  }
  dispatch(setOriginFollowUpPrescriptions(data.prescriptions))
}

export function sendGetMedicationFollowUpPrescriptions(idToken: string | undefined, shopId: string) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/{shopId}/prescriptions/medication-follow-up
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/prescriptions/medication-follow-up`,
      idToken,
      null,
      dispatch,
      didCallGetMedicationFollowUpPrescriptionsApi,
    )
  }
}
