import React from 'react'
import { hooks } from 'libs'
import { Usecases } from 'core'
import { usePrescriptions } from 'libs/hooks/usePrescriptions'
import DonePrescriptionsPage from 'components/screens/DonePrescriptionsPage'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { clearDonePrescriptions } from 'core/modules/donePrescriptions'

export default function DonePrescriptionsPageContainer() {
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const dispatch = useAppDispatch()
  const token = hooks.useAuth().idToken
  const { filteredDonePrescriptions, setFilterForDone, setSortOrderForDone, displayConditions } = usePrescriptions()

  const handleFilterChange = (filter: string) => {
    setFilterForDone(filter)
  }

  const handleOrderChange = (newSortKey: string) => {
    setSortOrderForDone(newSortKey)
  }

  const handlePrescriptionsUpdate = () => {
    if (!shopId) return
    dispatch(clearDonePrescriptions())
    dispatch(Usecases.getInProcessPrescriptions.sendGetInProcessPrescriptions(token, shopId))
    dispatch(Usecases.getDonePrescriptions.sendGetDonePrescriptions(token, shopId))
  }

  return (
    <DonePrescriptionsPage
      shopId={shopId}
      prescriptions={filteredDonePrescriptions}
      filter={displayConditions.filterForDone}
      sortOrder={displayConditions.sortOrderForDone}
      handleFilterChange={(filter: string) => handleFilterChange(filter)}
      handleOrderChange={(order: string) => handleOrderChange(order)}
      handlePrescriptionsUpdate={() => {
        handlePrescriptionsUpdate()
      }}
    />
  )
}
