import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setMessages } from 'core/modules/messages'
import { store } from 'core/store'

type GetMessagesPayload = Domain.MedicationFollowUp.GetMessagesPayload
type GetMessagesResult = Domain.MedicationFollowUp.GetMessagesResult

function didCallSetSendGetInitialMessagesApi(dispatch: Dispatch, data: GetMessagesResult | Error) {
  if (data instanceof Error) {
    return
  }
  dispatch(setMessages(data))
}

function didCallSetSendGetMessagesApi(dispatch: Dispatch, data: GetMessagesResult | Error) {
  if (data instanceof Error) {
    return
  }
  const messageList = store.getState().messages.messages.messageList
  if (messageList.length === 0) {
    const messages = [...data.messageList]
    dispatch(
      setMessages({ messageList: messages, exclusiveStartKey: data.exclusiveStartKey, isCompleted: data.isCompleted }),
    )
  } else {
    const messages = [...data.messageList, ...messageList]
    dispatch(
      setMessages({ messageList: messages, exclusiveStartKey: data.exclusiveStartKey, isCompleted: data.isCompleted }),
    )
  }
}

export function sendGetInitialMessages(
  idToken: string | undefined,
  shopId: string | null,
  payload: GetMessagesPayload,
) {
  const query =
    '?patientLineId=' +
    payload.patientLineId +
    '&perPage=' +
    payload.perPage.toString() +
    (payload.id && '&id=' + payload.id) +
    (payload.sendAt && '&sendAt=' + payload.sendAt)
  return async (dispatch: Dispatch) => {
    // Send GET API
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/messages/${query}`,
      idToken,
      null,
      dispatch,
      didCallSetSendGetInitialMessagesApi,
    )
  }
}

export function sendGetMessages(idToken: string | undefined, shopId: string | null, payload: GetMessagesPayload) {
  const query =
    '?patientLineId=' +
    payload.patientLineId +
    '&perPage=' +
    payload.perPage.toString() +
    (payload.id && '&id=' + payload.id) +
    (payload.sendAt && '&sendAt=' + payload.sendAt)
  return async (dispatch: Dispatch) => {
    // Send GET API
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/messages/${query}`,
      idToken,
      null,
      dispatch,
      didCallSetSendGetMessagesApi,
    )
  }
}
