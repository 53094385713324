import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useAppNavigate = () => {
  const navigateFunction = useNavigate()

  const navigate = useCallback(
    (path: string, state?: unknown) => {
      navigateFunction(path, { state })
    },
    [navigateFunction],
  )

  const navigateReplace = useCallback(
    (path: string, state?: unknown) => {
      navigateFunction(path, { state, replace: true })
    },
    [navigate],
  )

  const navigatePop = useCallback(() => {
    navigateFunction(-1)
  }, [navigate])

  return {
    navigate,
    navigateReplace,
    navigatePop,
  }
}
