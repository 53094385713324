import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from 'components/atoms'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface TextInputFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  error?: FieldError | undefined
  register: UseFormRegisterReturn
  defaultValue?: string
  placeholder?: string
  width?: string
}

const Row = styled(Box)<{ alignItems?: string }>({
  display: 'flex',
  flexDirection: 'row',
  margin: '6px',
  flexWrap: 'wrap',
  alignItems: 'center',
})

const InputLabel = styled(Box)({
  display: 'flex',
  margin: '0 18px',
})

const TextInput = styled('input', {
  shouldForwardProp: prop => prop !== 'error',
})<{ width?: string; error: boolean }>(({ theme, width = '240px', error }) => ({
  height: '46px',
  fontSize: '16px',
  width,
  paddingLeft: '10px',
  border: `solid 1px ${error ? theme.palette.warning.main : 'black'}`,
  '&:focus': {
    outline: 'none',
  },
}))

const ErrorMessage = styled('div')(({ theme }) => ({
  marginLeft: '60px',
  color: theme.palette.warning.main,
}))

export default function TextInputField(props: TextInputFieldProps) {
  const { label = '', error, register, defaultValue, placeholder = '', width } = props

  return (
    <div>
      <Row>
        <InputLabel>{label}</InputLabel>
        <TextInput
          placeholder={placeholder}
          width={width}
          error={error != null}
          defaultValue={defaultValue}
          {...register}
        />
      </Row>
      {error != null && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  )
}
