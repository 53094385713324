import React from 'react'
import { styled } from '@mui/material/styles'
import { CommonButton, Typography, Box } from 'components/atoms'
import icoClose from 'assets/images/ico_close.svg'

const ModalContainerBox = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#00000080',
  zIndex: 9999,
})

const ModalBox = styled(Box)<{islarge?: string}>(({islarge = false}) => ({
  width: islarge === 'true' ? 477 : 241,
  borderRadius: 5,
  background: '#ffffff',
  overflow: 'hidden',
}))

const TitleWrapperBox = styled(Box)({
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#EFEFEF',
  display: 'flex',
  justifyContent: 'space-between',

})

const DescriptionWrapperBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
})

const ModalFooterBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderWidth: 0,
  borderTopWidth: 1,
  borderStyle: 'solid',
  borderColor: '#EFEFEF',
})

const Icon = styled('img')({
  marginTop: 3,
  width: 16,
  height: 16,
})

/**
 * Interface
 */

interface confirmModalProps {
  isOpened: boolean
  title?: string
  description: string
  hasClose?: boolean
  doneButtonLabel?: string
  cancelButtonLabel?: string
  onClickDone: () => void
  onClickCancel?: () => void
  onClickClose?: () => void
  isLarge?: boolean
}

export default function ConfirmModal(props: confirmModalProps) {
  const { isOpened, title, description, doneButtonLabel, cancelButtonLabel } = props

  if (!isOpened) {
    return null
  }

  return (
    <ModalContainerBox>
      <ModalBox islarge={props.isLarge?.toString()}>
        {title && (
          <TitleWrapperBox padding={2} borderBottom={1}>
            <Typography variant="caption">{title}</Typography>
            {props.hasClose && <Icon src={icoClose} onClick={props.onClickClose}/>}
          </TitleWrapperBox>
        )}
        <DescriptionWrapperBox padding={2}>
          <Typography variant="caption">{description}</Typography>
        </DescriptionWrapperBox>
        <ModalFooterBox padding={2}>
          {cancelButtonLabel && (
            <CommonButton type={'secondary'} label={cancelButtonLabel} click={props.onClickCancel} />
          )}
          <CommonButton type={'primary'} label={doneButtonLabel ? doneButtonLabel : 'OK'} click={props.onClickDone} />
        </ModalFooterBox>
      </ModalBox>
    </ModalContainerBox>
  )
}
