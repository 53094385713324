import React from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress, Card, Box, Typography } from 'components/atoms'

const RootBox = styled(Box)({
  height: '100%',
})

const PanelCard = styled(Card)({
  width: '240px',
})

const PanelInnerCard = styled(Box)({
  height: '120px',
})

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  display: 'block',
  color: theme.palette.brand[500],
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.grey[700],
  fontSize: '12px',
}))

export default function LoadingPanel() {
  return (
    <RootBox display="flex" justifyContent="center" alignItems="center">
      <PanelCard>
        <PanelInnerCard display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <StyledCircularProgress size={32} />
          <StyledTypography display="block">読み込み中</StyledTypography>
        </PanelInnerCard>
      </PanelCard>
    </RootBox>
  )
}
