import React, { useMemo } from 'react'
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CommonButton,
  CheckListIcons,
  Tab,
  Tabs,
} from 'components/atoms'
import icoArrowRight from 'assets/images/ico_arrow_right.svg'
import { styled } from '@mui/material/styles'
import { PageHeader } from 'components/molecules'
import { SectionPanel } from 'components/molecules'
import icoLoop from 'assets/images/ico_loop.svg'
import {
  PrescriptionWithMedicationFollowUpStatusType as PrescriptionType,
  PrescriptionsHeaderItem,
} from 'libs/hooks/useMedicationFollowUp'
import { STATUS_FILTERS, PRESCRIPTIONS_HEADER_ITEMS } from 'libs/hooks/useMedicationFollowUp'
import { StatusFilter } from 'libs/hooks/usePrescriptions'
import { date } from 'libs'
import { Domain } from 'core'

const StyledBox = styled(Box)<{ filter: boolean }>(({ theme, filter }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(54)})`,
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
  ...(filter && {
    height: `calc(100% - ${theme.typography.pxToRem(54)} - ${theme.typography.pxToRem(64)})`,
  }),
}))

const StyledTableRow = styled(TableRow)<{ isread: string }>(({ isread }) => ({
  '&:hover': {
    backgroundColor: '#BBDEFB !important',
  },
  ...(isread === 'false' && {
    backgroundColor: '#2196F314',
  }),
}))

const SectionHeadTypography = styled(Typography)({
  fontWeight: 'normal',
})

const StyledTableMessageCell = styled(TableCell)<{ isstartedmessage: string; ismessageunreadbyuser: string }>(
  ({ isstartedmessage, ismessageunreadbyuser }) => ({
    ...(isstartedmessage === 'true' &&
      ismessageunreadbyuser === 'true' && {
        color: '#F50057',
        fontWeight: 'bold',
      }),
  }),
)

const FilterTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  '& .MuiTabs-indicator': {
    backgroundColor: '#F50057',
  },
}))

const StyledTab = styled(Tab)<{ isSameIndex: boolean }>(({ isSameIndex }) => ({
  width: 124,
  minWidth: 124,
  ...(isSameIndex && {
    color: '#F50057',
  }),
}))

const StyledTableContainer = styled(TableContainer)({
  height: '100%',
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}))

const StyledTableCell = styled(TableCell)({
  '& span': {
    width: '100%',
  },
  '& svg': {
    marginLeft: 'auto',
    opacity: '0.54 !important',
  },
})

const IconImg = styled('img')({
  width: 10,
  height: 10,
})

type SortOrder = Domain.Prescription.SortOrder

/**
 * interface
 */
interface Props {
  prescriptions: Array<PrescriptionType>
  filter: string
  handlePrescriptionsUpdate: () => void
  handleFilterChange: (filter: string) => void
  handleOrderChange: (sort: PrescriptionsHeaderItem) => void
  onClick: (prescriptionId: string) => void
  sortOrder: SortOrder
}

export default function MedicationFollowUp(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterTabChange = (event: any, tabIndex: number) => {
    if (props.handleFilterChange) props.handleFilterChange(STATUS_FILTERS[tabIndex].filter)
  }

  const getMessageStatus = (isStartedMessage: boolean, isMessageUnreadByUser: boolean): string => {
    if (!isStartedMessage) return 'なし'
    return isMessageUnreadByUser ? '未読' : '既読'
  }

  const tabIndex = useMemo(() => {
    return STATUS_FILTERS.findIndex(status => status.filter === props.filter)
  }, [props.filter])

  return (
    <Box>
      <PageHeader>
        <Typography variant="h1" component="h2">
          服薬フォロー中の処方せん
        </Typography>
      </PageHeader>
      <SectionPanel
        header={
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <SectionHeadTypography variant="h6">処方せん一覧</SectionHeadTypography>
            <CommonButton
              type={'primary'}
              label={'更新'}
              icon={icoLoop}
              click={() => {
                props.handlePrescriptionsUpdate()
              }}
            />
          </Box>
        }>
        <StyledBox paddingLeft={2} paddingRight={2} paddingBottom={2} filter={!!props.filter}>
          <Box>
            <FilterTabs value={tabIndex} onChange={handleFilterTabChange} aria-label="filter tabs">
              {STATUS_FILTERS.map((filter: StatusFilter, index: number) => {
                return <StyledTab isSameIndex={index === tabIndex} label={filter.label} key={index} />
              })}
            </FilterTabs>
          </Box>
          <StyledTableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  {PRESCRIPTIONS_HEADER_ITEMS.map((item: PrescriptionsHeaderItem, index: number) => {
                    return (
                      <StyledTableCell key={index}>
                        <TableSortLabel
                          active={true}
                          direction={props.sortOrder.sortKey === item.sortKey ? props.sortOrder.order : 'desc'}
                          onClick={() => {
                            props.handleOrderChange(item)
                          }}>
                          {item.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    )
                  })}
                  <TableCell></TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {props.prescriptions.map((prescription: PrescriptionType, index: number) => {
                  return (
                    <StyledTableRow
                      isread={prescription.isRead.toString()}
                      hover
                      key={index}
                      onClick={() => props.onClick(prescription.id.toString())}>
                      <TableCell scope="row">{prescription.acceptedCode}</TableCell>
                      <TableCell>{date.formatDate(prescription.acceptedAt)}</TableCell>
                      <TableCell>{prescription.name}</TableCell>
                      <TableCell>{prescription.imageCount}</TableCell>
                      <TableCell>
                        <CheckListIcons prescription={prescription} />
                      </TableCell>
                      <StyledTableMessageCell
                        isstartedmessage={prescription.isStartedMessage.toString()}
                        ismessageunreadbyuser={prescription.isMessageUnreadByUser.toString()}>
                        {getMessageStatus(prescription.isStartedMessage, prescription.isMessageUnreadByUser)}
                      </StyledTableMessageCell>
                      <TableCell>
                        <IconImg src={icoArrowRight} alt={`${index}`} />
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledBox>
      </SectionPanel>
    </Box>
  )
}
