import React from 'react'
import { styled } from '@mui/material/styles'
import { ListItemText } from 'components/atoms'
import { ListItemButton } from '@mui/material'

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  height: 44,
  width: '100%',
  textAlign: 'left',
  paddingLeft: 16,
  paddingRight: 16,
  backgroundColor: theme.palette.common.white,
}))

const StyledListItemIcon = styled('img')({
  width: 20,
  height: 20,
  marginRight: 8,
})

const StyledListItemText = styled(ListItemText)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: 0,
})

/**
 * Interface
 */

interface SidebarMenuItemProps {
  title: string
  iconPath: string
  onClick: () => void
}

export default function SidebarMenuItem(props: SidebarMenuItemProps) {
  return (
    <StyledListItemButton onClick={() => props.onClick()}>
      {props.iconPath && <StyledListItemIcon src={props.iconPath} alt={props.title} />}
      <StyledListItemText secondary={props.title} />
    </StyledListItemButton>
  )
}
