import React from 'react'
import { styled } from '@mui/material/styles'
import { PRESCRIPTION_STATUS } from 'constants/text'


interface StatusChipStyleProps {
  currentStatus: StatusType
}

/**
 * classes.label と classes.status を廃止して一つに整理して修正
 * @see https://mui.com/material-ui/migration/migrating-from-jss/#1-use-styled-or-sx-api
 */
const Root = styled('span')<StatusChipStyleProps>(({ theme, currentStatus }) => ({
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
    minWidth: '130px',
    minHeight: '24px',
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: currentStatus.backgroundColor,
    color: currentStatus.color,
}))


/**
 * Interface
 */

interface StatusChipProps {
  status: string
}

/**
 * Interface
 * @new : 処方せん確認中ステータス
 * @dispensing : 調剤中ステータス
 * @dispensed : 調剤完了ステータス
 * @completed : お渡し済みステータス
 */
interface StatusTypes {
  [key: string]: StatusType
  new: StatusType
  dispensing: StatusType
  dispensed: StatusType
  completed: StatusType
  canceled: StatusType
}

/**
 * Interface
 * @text : ステータスの表示用文字列
 * @backgroundColor : ステータスチップの背景色
 */
interface StatusType {
  text: string
  backgroundColor: string
  color: string
}

/** 処方せんのステータス定義 */
const STATUS_TYPES: StatusTypes = {
  new: {
    text: PRESCRIPTION_STATUS.NEW,
    backgroundColor: '#E74C3C',
    color: '#FFFFFF',
  },
  dispensing: {
    text: PRESCRIPTION_STATUS.DISPENSING,
    backgroundColor: '#E67E22',
    color: '#FFFFFF',
  },
  dispensed: {
    text: PRESCRIPTION_STATUS.DISPENSED,
    backgroundColor: '#27AE60',
    color: '#FFFFFF',
  },
  followup: {
    text: PRESCRIPTION_STATUS.FOLLOWUP,
    backgroundColor: '#5729B9',
    color: '#FFFFFF',
  },
  completed: {
    text: PRESCRIPTION_STATUS.COMPLETED,
    backgroundColor: '#2980B9',
    color: '#FFFFFF',
  },
  canceled: {
    text: PRESCRIPTION_STATUS.CANCELED,
    backgroundColor: '#7F8C8D',
    color: '#FFFFFF',
  },
}

export default function StatusChip(props: StatusChipProps) {
  const currentStatus = STATUS_TYPES[props.status]

  return (
    <Root currentStatus={currentStatus}>
      {currentStatus.text}
    </Root>
  )
}