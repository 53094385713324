import * as React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { AuthProvider } from 'contexts/AuthContext'

import { Router } from 'Routes'
import { Theme } from 'styles'

import { Store } from 'core'

const authCredential = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
  namespace: process.env.REACT_APP_AUTH0_NAMESPACE as string,
}

export default function App() {
  return (
    <Provider store={Store.store}>
      <ThemeProvider theme={Theme.tomodsMcTheme}>
        <CssBaseline />
        <AuthProvider value={authCredential}>
          <Router />
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  )
}
