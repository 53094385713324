/* eslint-disable */
import { Dispatch } from 'redux'
import * as Api from 'libs/api'

export function sendMessgesRead(idToken: string | undefined, shopId: string | null, patientLineId: string | undefined) {
  return async (dispatch: Dispatch) => {
    // Send PUT API
    await Api.callApi(
      Api.HttpMethod.put,
      `shops/${shopId}/messages/read`,
      idToken,
      JSON.stringify({ patientLineId: patientLineId }),
      dispatch,
    )
  }
}
