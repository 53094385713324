import { useState } from 'react'

/**
 * Interface
 */
interface CanvasInfo {
  width: number
  height: number
  x: number
  y: number
}

interface Axis {
  x: number
  y: number
}

/** 拡大・縮小の倍率 */
const zoomInRate = 1.2
const zoomOutRate = 0.8

/** 画像の向き */
type Vector = 'top' | 'left' | 'bottom' | 'right'

export const useViewer = () => {
  // Canvasのサイズ、軸
  const [canvasInfo, setCanvasInfo] = useState<CanvasInfo>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  // Canvasのサイズ、軸の初期値（デフォルト値に戻すときに使用）
  const [defaultCanvasInfo, setDefaultCanvasInfo] = useState<CanvasInfo>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  })
  // カーソル位置
  const [cursolAxis, setCursolAxis] = useState<Axis>({
    x: 0,
    y: 0,
  })
  // 画像の向き
  const [vector, setVector] = useState<Vector>('top')
  // canvas の context
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)
  // リサイズした画像
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  // ドラッグ中フラグ
  const [dragging, setDragging] = useState<boolean>(false)

  // 画像の存在チェック
  const existImage = async (imageData: string) => {
    const img = new Image()
    let res = true
    img.src = imageData
    await img.decode()
    .then(() => {})
    .catch(() => {res = false})
    return res
  }

  // 画像描画の初期化処理
  const initialDrawImage = (canvas: HTMLCanvasElement, imageData: string) => {
    const img = new Image()
    img.src = imageData
    img.onload = () => {
      canvas.width = canvas.clientWidth
      canvas.height = canvas.clientHeight
      // canvas の高さと画像の高さから割り出した比率を画像幅に掛け合わせた、canvas に入る画像サイズの算出
      const resizedImageWidth = img.width * (canvas.height / img.height)

      // CanvasのContextの設定
      // 描画時の軸をCanvasの中心に指定
      // 初期位置に戻すために、デフォルトのContext設定を保存
      const context = canvas.getContext('2d')
      if (context) {
        context.translate(canvas.width / 2, canvas.height / 2)
        context.save()
        setCtx(context)
      }

      // 画像の向きの初期値を設定
      setVector('top')

      // 初期化時に使用するために初期位置、サイズを保持
      const initialCanvasInfo = {
        ...canvasInfo,
        width: resizedImageWidth,
        height: canvas.height,
        x: resizedImageWidth / 2 * -1,
        y: canvas.height / 2 * -1,
      }

      // リサイズ後の画像の保持
      setImage(img)
      // 初期化時に使用するために初期位置、サイズを保持
      setDefaultCanvasInfo(initialCanvasInfo)
      // 位置、サイズを保持
      setCanvasInfo(initialCanvasInfo)
    }
  }

  // ------- 移動処理 --------
  // Canvasの移動の開始処理
  const moveImageStart = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>, canvas: HTMLCanvasElement) => {
    // マウスが押された座標を取得
    const axis = getCursolAxis(event, canvas)

    setCursolAxis({
      x: axis.x,
      y: axis.y,
    })

    setDragging(true)
  }

  // Canvasの移動処理
  const moveImage = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>, canvas: HTMLCanvasElement) => {
    // ドラッグが開始されていればオブジェクトの座標を更新して再描画
    if (!dragging) return

    const axis = getCursolAxis(event, canvas)
    const diff = getCursolDiff(axis.x - cursolAxis.x, axis.y - cursolAxis.y)

    setCanvasInfo({
      ...canvasInfo,
      x: canvasInfo.x + diff.x,
      y: canvasInfo.y + diff.y,
    })

    setCursolAxis({
      x: axis.x,
      y: axis.y,
    })
  }

  // Canvasの移動の終了処理
  const moveImageEnd = () => {
    setDragging(false)
  }

  // マウスが押された座標を取得
  const getCursolAxis = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>, canvas: HTMLCanvasElement): Axis => {
    const offsetX = canvas.getBoundingClientRect().left
    const offsetY = canvas.getBoundingClientRect().top

    // マウスが押された座標を取得
    const x = event.clientX - offsetX
    const y = event.clientY - offsetY

    return {x: x, y: y}
  }

  // ドラッグによる、Canvasの移動位置の差分を取得
  // Canvasの向きによって、x・yの移動方向を算出
  const getCursolDiff = (x: number, y: number): Axis => {
    switch (vector) {
      case 'top':
        return {x: x, y: y}
      case 'left':
        return {x: y, y: -x}
      case 'bottom':
        return {x: -x, y: -y}
      case 'right':
        return {x: -y, y: x}
    }
  }
  // ------------------------

  // ------- 回転処理 --------
  // Canvasの左回転
  const rotateLeft = () => {
    if (!ctx) return
    ctx.rotate(-90 * Math.PI / 180)
    rotateCommon(getRotateLeftOrigin())
  }

  // Canvasの右回転
  const rotateRight = () => {
    if (!ctx) return
    ctx.rotate(90 * Math.PI / 180)
    rotateCommon(getRotateRightOrigin())
  }

  // Canvasの回転の共通処理
  const rotateCommon = (origin: Axis) => {
    setCanvasInfo({
      x: origin.x,
      y: origin.y,
      width: canvasInfo.width,
      height: canvasInfo.height,
    })
    setVectorWrapper()
  }

  // 左回転による、Canvasの回転軸を取得
  // Canvasの向きによって、x・yの座標を取得
  const getRotateLeftOrigin = (): Axis => {
    return {x: (-canvasInfo.y) - canvasInfo.height + canvasInfo.width / 4, y: canvasInfo.x - ((canvasInfo.height - canvasInfo.width) / 2)}
  }

  // 右回転による、Canvasの回転軸を取得
  // Canvasの向きによって、x・yの座標を取得
  const getRotateRightOrigin = (): Axis => {
    return {x: canvasInfo.y + ((canvasInfo.height - canvasInfo.width) / 2), y: (-canvasInfo.x) - canvasInfo.height + canvasInfo.width / 4}
  }
  // ------------------------

  // ----- 拡大・縮小処理 ------
  // Canvasの拡大
  const zoomIn = () => {
    zoomCommon(zoomInRate)
  }

  // Canvasの縮小
  const zoomOut = () => {
    zoomCommon(zoomOutRate)
  }

  // Canvasの拡大・縮小の共通処理
  const zoomCommon = (zoomRate: number) => {
    setCanvasInfo({
      x: canvasInfo.x - (((canvasInfo.width * zoomRate) - canvasInfo.width) / 2),
      y: canvasInfo.y - (((canvasInfo.height * zoomRate) - canvasInfo.height) / 2),
      width: canvasInfo.width * zoomRate,
      height: canvasInfo.height * zoomRate,
    })
  }
  // ------------------------

  // Canvasの初期化
  const reset = () => {
    if (ctx) {
      ctx.restore()
      ctx.save()
      setCanvasInfo(defaultCanvasInfo)
      setVectorWrapper()
    }
  }

  // Canvasの再描画
  const redrawImage = (canvas: HTMLCanvasElement) => {
    clearImage(canvas)
    drawImage(canvasInfo)
  }

  // Canvasの描画
  const drawImage = (info: CanvasInfo) => {
    if (!ctx || !image) return
    ctx.drawImage(image, info.x, info.y, info.width, info.height)
  }

  // Canvasの削除
  const clearImage = (canvas: HTMLCanvasElement) => {
    if (ctx) ctx.clearRect(canvas.clientWidth / 2 * -1, canvas.clientWidth / 2 * -1, canvas.clientWidth, canvas.clientWidth)
  }

  // Canvasの向きの設定
  // ContextのTransformのMatrix情報から向きを算出
  const setVectorWrapper = () => {
    if (ctx) {
      const { a, b, c, d } = ctx.getTransform()
      if (a === 1 && d === 1) return setVector('top')
      if (b === 1 && c === -1) return setVector('left')
      if (a === -1 && d === -1) return setVector('bottom')
      if (b === -1 && c === 1) return setVector('right')
    }
  }

  return {
    existImage,
    dragging,
    canvasInfo,
    initialDrawImage,
    redrawImage,
    moveImageStart,
    moveImage,
    moveImageEnd,
    rotateLeft,
    rotateRight,
    zoomIn,
    zoomOut,
    reset
  }
}
