import { createAction, createReducer } from '@reduxjs/toolkit'
import { ShopInformation } from 'core/domain/shopInformation'

type State = {
  shopInformation?: ShopInformation
  isSaveSuccess?: boolean
}

export function createInitialState(): State {
  return { shopInformation: undefined, isSaveSuccess: undefined }
}

const SET_SHOP_INFORMATION = 'shop-information/set' as const
const CLEAR_SHOP_INFORMATION = 'shop-information/clear' as const
const SET_IS_SAVE_SUCCESS = 'shop-information/is-success/set' as const
const CLEAR_IS_SAVE_SUCCESS = 'shop-information/is-success/clear' as const

export const setShopInformation = createAction<ShopInformation>(SET_SHOP_INFORMATION)
export const clearShopInformation = createAction(CLEAR_SHOP_INFORMATION)
export const setIsSaveSuccess = createAction<boolean>(SET_IS_SAVE_SUCCESS)
export const clearIsSaveSuccess = createAction(CLEAR_IS_SAVE_SUCCESS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setShopInformation, (state, action) => {
      state.shopInformation = action.payload
    })
    .addCase(clearShopInformation, state => {
      state.shopInformation = undefined
    })
    .addCase(setIsSaveSuccess, (state, action) => {
      state.isSaveSuccess = action.payload
    })
    .addCase(clearIsSaveSuccess, state => {
      state.isSaveSuccess = undefined
    })
    .addDefaultCase(() => {
      return
    })
})
