import { createAction, createReducer } from '@reduxjs/toolkit'

export type State = { error: Error | null }

export function createInitialState(): State {
  return { error: null }
}

const SET_ERROR = 'error/set' as const
const CLEAR_ERROR = 'error/clear' as const

export const setError = createAction<Error>(SET_ERROR)
export const clearError = createAction(CLEAR_ERROR)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setError, (state, action) => {
      state.error = action.payload
    })
    .addCase(clearError, state => {
      state.error = null
    })
    .addDefaultCase(() => {
      return
    })
})
