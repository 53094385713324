import React from 'react'
import { styled } from '@mui/material/styles'

const RootDiv = styled('div')({
  display: 'grid',
  fallbacks: {
    display: '-ms-grid',
  },
  background: '#F5F5F5',
  width: '100%',
  height: '100%',
  '-ms-grid-rows': '64px 1fr 64px',
  '-ms-grid-columns': '1fr',
  gridTemplate: `
  'pageHeaderBar' 64px
  'pageContent' auto
  'pageFooter' 64px
`,
})

const HeaderBarDiv = styled('div')(({ theme }) => ({
  gridArea: 'pageHeaderBar',
  '-ms-grid-row': 1,
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
  top: 0,
  left: 0,
}))

const ContentDiv = styled('div')(({ theme }) => ({
  gridArea: 'pageContent',
  '-ms-grid-row': 2,
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}))

const ContentInnerDiv = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '0 1rem',
  [theme.breakpoints.up('sm')]: {
    width: '1084px',
  },
}))

/**
 * Interface
 */

export interface GenericTemplateProps {
  headerBar: React.ReactNode
  children: React.ReactNode
}

export default function GenericTemplate(props: GenericTemplateProps) {
  return (
    <RootDiv>
      <HeaderBarDiv>{props.headerBar && props.headerBar}</HeaderBarDiv>
      <ContentDiv>
        <ContentInnerDiv>{props.children}</ContentInnerDiv>
      </ContentDiv>
    </RootDiv>
  )
}
