import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Icon } from 'components/atoms'

const LogTypeH1 = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(34),
  fontWeight: 'normal',
}))

const InnerDiv = styled('div')({
  width: '400px',
  padding: '40px',
  margin: 'auto',
})

const SectionTitleH1 = styled('h1')({
  textAlign: 'center',
  marginBottom: '2rem',
})

const SectionBodyP = styled('p')({
  textAlign: 'left',
  marginBottom: '2rem',
})
const CopyrightSmall = styled('small')({
  display: 'block',
  marginTop: '1rem',
  textAlign: 'center',
})

/**
 * interface
 */

export interface LoginSectionProps {
  handleLogin: () => void
}

export default function LoginSection(props: LoginSectionProps) {
  return (
    <div>
      <LogTypeH1>どこでも処方せん送信管理画面</LogTypeH1>
      <Box boxShadow={1}>
        <InnerDiv>
          <section>
            <SectionTitleH1>ログイン</SectionTitleH1>
            <SectionBodyP>アカウントをお持ちの方は、 こちらよりログインしてください。</SectionBodyP>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              size="large"
              startIcon={<Icon iconName="sign-in-alt" size="xs" />}
              onClick={props.handleLogin}>
              ログイン
            </Button>
          </section>
        </InnerDiv>
      </Box>
      <CopyrightSmall>© Classmethod, Inc.</CopyrightSmall>
    </div>
  )
}
