import { Dispatch } from 'redux'
import { Domain } from 'core'
import * as Api from 'libs/api'
import { setPrescriptionDetail } from 'core/modules/prescriptionDetail'

type Prescription = Domain.Prescription.Prescription

function didCallSetPrescriptionDetailApi(dispatch: Dispatch, data: Prescription | Error) {
  if (data instanceof Error) return
  dispatch(setPrescriptionDetail(data))
}

export function sendPutPrescriptionStatus(
  idToken: string | undefined,
  shopId: string,
  prescriptionId: string,
  status: string,
) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/${shopId}/prescriptions/${prescriptionId}/status
    await Api.callApi(
      Api.HttpMethod.put,
      `shops/${shopId}/prescriptions/${prescriptionId}/status`,
      idToken,
      JSON.stringify({ status: status }),
      dispatch,
      didCallSetPrescriptionDetailApi,
    )
  }
}

export function sendPutPrescriptionStatusWithMinute(
  idToken: string | undefined,
  shopId: string,
  prescriptionId: string,
  status: string,
  minute: number,
) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/${shopId}/prescriptions/${prescriptionId}/status
    await Api.callApi(
      Api.HttpMethod.put,
      `shops/${shopId}/prescriptions/${prescriptionId}/status`,
      idToken,
      JSON.stringify({ status: status, minute: minute }),
      dispatch,
      didCallSetPrescriptionDetailApi,
    )
  }
}
