import * as yup from 'yup'

const timeExp = /([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/
const timeRangeExp = new RegExp(`^(${timeExp.source})-(${timeExp.source})$`)
const multiRangeExp = new RegExp(`^(${timeExp.source})-(${timeExp.source})/(${timeExp.source})-(${timeExp.source})$`)
const holidayExp = new RegExp(`^(休)$`)
const emptyExp = new RegExp(`^$`)
const combineExp = new RegExp(
  `(${timeRangeExp.source})|(${multiRangeExp.source})|(${holidayExp.source})|(${emptyExp.source})`,
)

export const shopInformationSchemas = yup.object().shape({
  opening: yup.object().shape({
    monday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    tuesday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    wednesday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    thursday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    friday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    saturday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    sunday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    holiday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
  }),
  available: yup.object().shape({
    monday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    tuesday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    wednesday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    thursday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    friday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    saturday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    sunday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
    holiday: yup.string().matches(combineExp, '形式が正しくありません').default(''),
  }),
  comment: yup.string().default(''),
})
