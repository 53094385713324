import React from 'react'
import { styled } from '@mui/material/styles'
import { Paper } from 'components/atoms'

const StyledSection = styled('section')({
  height: '100%',
})

const Root = styled(Paper)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.typography.pxToRem(4),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#EFEFEF',
}))

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const Body = styled('div')({
  height: '100%',
  padding: 0,
})

/**
 * Interface
 */

interface SectionPanelProps {
  header?: React.ReactElement | React.ReactNode
  children: React.ReactNode
}

export default function SectionPanel(props: SectionPanelProps) {
  const { header, children } = props

  return (
    <StyledSection>
      <Root>
        {header && (
          <>
            <Header>{header}</Header>
          </>
        )}
        {children && <Body>{children}</Body>}
      </Root>
    </StyledSection>
  )
}
