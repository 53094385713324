import * as React from 'react'
import { Outlet } from 'react-router'

/**
 * NavigationContext API
 */

interface NavigationValue {
  sidebarDisplayCondition: boolean
  // ----
  handleSidebarDisplayCondition: (condition: boolean) => void
}

const NavigationContext = React.createContext<NavigationValue>({
  sidebarDisplayCondition: true,
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  handleSidebarDisplayCondition: () => {},
})

const useNavigation = (): NavigationValue => {
  /**
   * Navigation state
   */
  const [sidebarDisplayCondition, setSidebarDisplayCondition] = React.useState<boolean>(true)

  /**
   * Handle sidebar component display condition state
   */
  const handleSidebarDisplayCondition = React.useCallback(
    (condition: boolean) => {
      setSidebarDisplayCondition(condition)
    },
    [setSidebarDisplayCondition],
  )

  /**
   * Handle close Sidebar
   */

  return {
    // state
    sidebarDisplayCondition,
    // action
    handleSidebarDisplayCondition,
  }
}

/**
 * NavigationProvider API
 */

export const NavigationProvider: React.FC = () => {
  // eslint-disable-next-line react/prop-types
  const value = useNavigation()

  return (
    <NavigationContext.Provider value={value}>
      <Outlet />
    </NavigationContext.Provider>
  )
}

export const NavigationConsumer = NavigationContext.Consumer

export default NavigationContext
