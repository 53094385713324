import React from 'react'
import { LoginSection } from 'components/organisms'
import { CenterAlignmentTemplate } from 'components/templates'

/**
 * Interface
 */

interface LoginPageProps {
  actions: {
    handleLogin: () => void
  }
}

export default function LoginPage(props: LoginPageProps) {
  const {
    actions: { handleLogin },
  } = props
  return (
    <CenterAlignmentTemplate>
      <LoginSection handleLogin={handleLogin} />
    </CenterAlignmentTemplate>
  )
}
