import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { ShopInformation } from 'core/domain/shopInformation'
import { setIsSaveSuccess } from 'core/modules/shopInformation'

export type PutShopInformationProc = ShopInformation

function didCallSetShopInformationApi(dispatch: Dispatch, data: ShopInformation | Error) {
  if (data instanceof Error) {
    dispatch(setIsSaveSuccess(false))
    return
  }
  dispatch(setIsSaveSuccess(true))
}

export function sendPutShopInformation(idToken: string | undefined, shopId: string, proc: PutShopInformationProc) {
  return async (dispatch: Dispatch) => {
    // Send GET /mc/shops/${shopId}/prescriptions/${prescriptionId}/status
    await Api.callApi(
      Api.HttpMethod.put,
      `shops/${shopId}/put-information`,
      idToken,
      JSON.stringify(proc),
      dispatch,
      didCallSetShopInformationApi,
    )
  }
}
