import React from 'react'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(6),
}))

/**
 * Interface
 */

export type PageHeaderProps = {
  children: React.ReactElement | React.ReactNode
}

export default function PageHeader(props: PageHeaderProps) {
  const { children } = props

  return <Root>{children}</Root>
}
